import { PageTransition } from "@steveeeie/react-page-transition"
import { useAppDispatch, useAppSelector } from "app/hooks";
import { cbnoQuestion } from "features/CBNO/cbnoListQuestion";
import { handleNext, selectDirection, selectDossierChoices, selectDossierIntermediateStep, setIntermediateStep } from "features/dossierSimuSlice";
import { getCbnoCurrentQuestions } from "helpers/getSimulationSteps";
import React, { useEffect, useState } from "react"

type Props = {
    handleChoice: (fieldName: string, value: boolean | string | number) => void;
}

const Question: React.FC<Props> = ({handleChoice}) => {
    // "moveToLeftFromRight" : "moveToRightFromLeft"
    const intermediateStep = useAppSelector(selectDossierIntermediateStep);
    const direction = useAppSelector(selectDirection);
    const dispatch = useAppDispatch();
    const [currentQuestion, setCurrentQuestion] = useState<cbnoQuestion>();
    const dossierCBNO = useAppSelector(selectDossierChoices);

    // const handleSetAnwers = (step:number, intermediateStep: number, fieldname:string, value:string) => {
    // }
    useEffect(() => {
        setCurrentQuestion(getCbnoCurrentQuestions(intermediateStep));
    }, [intermediateStep])

    const isEligibleStep = () => {
        if (dossierCBNO.TypeResidence === "Résidence principale" || dossierCBNO.TypeResidence ===  "Résidence secondaire")
            return (9);
        if (dossierCBNO.Situation === "Une résidence de services (EHPAD, etc.)" || dossierCBNO.Situation === "Une résidence de tourisme")
            return (9);
        else
            return (10);
    }

    const selectChoice = (value: string | boolean | number) => {
        if (currentQuestion?.fieldName)
            handleChoice(currentQuestion?.fieldName, value);

        if (intermediateStep === 0) {
            if (value === 3) {
                dispatch(setIntermediateStep(1));
            } else {
                dispatch(setIntermediateStep(2));
            }
            return ;
        } 

        if (intermediateStep === 8) {
            dispatch(setIntermediateStep(isEligibleStep()));
        }
        else {
            dispatch(handleNext());
        }
    }

    return (
        <PageTransition
          preset={direction}
          transitionKey={intermediateStep?.toString()}
          enterAnimation={""}
          exitAnimation={""}
        >
        { currentQuestion ?
            <div className="question-container">
                <p className="mg-r5 mg-l5">{currentQuestion?.question}</p>
                {
                    currentQuestion?.description &&
                    <p className="text-dark-light mg-r5 mg-l5 mg-t10 description">{currentQuestion?.description}</p>
                }
                {
                    currentQuestion?.options?.map((option, index) => {
                        return (
                            <>
                            
                                <button onClick={() => selectChoice(option.value)} key={index} className={`w-100 question-button mg-b10 ${option?.icon ? "" : "flex-hor-center"}`}>
                                {option?.icon && (
                                    <img src={option.icon}></img>
                                )}
                                    <p className={`${option?.icon ? "text-left" : "w-100"}`}>{option.libelle}</p>
                                </button>
                            </>
                        )
                    })
                }
            </div>: null
        }
         </PageTransition>
    )
}

export default Question;