
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useState } from "react";
import { handleNext, selectDossierChoices } from "features/dossierSimuSlice";
import home from "assets/image/emprunteur/home (2).svg"
import vacances from "assets/image/emprunteur/Groupe 2856.svg"
import maisonCle from "assets/image/emprunteur/Groupe 2858.svg"
import travaux from "assets/image/emprunteur/travaux.svg"
import commerces from "assets/image/emprunteur/commerces.svg"
import racImmo from "assets/image/emprunteur/racImmo.svg"
import { useIsSmallScreen } from "hooks/isSmallScreen";


type Props = {
    handleOnClick: (
      fieldName: string,
      value: boolean | string | number
    ) => void;
};

const ProjetCredit: React.FC<Props> = ({handleOnClick }) => {
    const dossierChoices = useAppSelector(selectDossierChoices);
    const [selectedValue, setSelectedValue] = useState<string>(dossierChoices?.Projet);
    const isSmallScreen = useIsSmallScreen(567);
    const dispatch = useAppDispatch();

    console.log("select", selectedValue);

    return (
    <div id="projet">
         <div className={`container`}>
                <div className="question-container">
                    <p className="font-18 text-dark">Décrivez-nous votre projet de crédit</p>
                    <button onClick={() => setSelectedValue("Residence principale")} 
                        className={`w-100 question-button mg-top  ${selectedValue === "Residence principale" ? "active" : ""}`}
                    >
                        <img src={home} alt="Home"></img>
                        <p className={`text-left `}>Résidence principale</p>
                    </button>
                    <button onClick={() => setSelectedValue("Résidence secondaire")} 
                        className={`w-100 question-button mg-top  ${selectedValue === "Résidence secondaire" ? "active" : ""}`}
                    >
                        <img src={vacances} alt="Tongues"></img>
                        <p className={`text-left `}>Résidence secondaire</p>
                    </button>
                    <button onClick={() => setSelectedValue("Achat locatif")}
                        className={`w-100 question-button mg-top  ${selectedValue === "Achat locatif" ? "active" : ""}`}
                    >
                        <img src={maisonCle} alt="Home"></img>
                        <p className={`text-left `}>Achat locatif</p>
                    </button>
                    <button onClick={() => setSelectedValue("Travaux")}
                        className={`w-100 question-button mg-top  ${selectedValue === "Travaux" ? "active" : ""}`}
                    >
                        <img src={travaux} alt="Home"></img>
                        <p className={`text-left `}>Travaux</p>
                    </button>
                    <button onClick={() => setSelectedValue("Crédit consommation")}
                        className={`w-100 question-button mg-top  ${selectedValue === "Crédit consommation" ? "active" : ""}`}
                    >
                        <img src={commerces} alt="Home"></img>
                        <p className={`text-left`}>Crédit à la consommation</p>
                    </button>
                    <button onClick={() => setSelectedValue("Rac Immo")}
                        className={`w-100 question-button mg-top  ${selectedValue === "Rac Immo" ? "active" : ""}`}
                    >
                        <img src={racImmo} alt="Home"></img>
                        <p className={`text-left`}>RAC Immo</p>
                    </button>
                </div>
            
        </div>
        <div className={`w-90 d-flex ${isSmallScreen ? "flex-hor-center" : "flex-hor-end"}`}>
            <div className="submit-container w-50">
                <button
                    className="submitButton"
                    disabled={selectedValue === undefined}
                    onClick={() =>  {
                        handleOnClick("Projet", selectedValue)
                        dispatch(handleNext());
                    }}
                >
                    Étape suivante
                </button>
            </div>
        </div>
    </div>
    )
}

export default ProjetCredit;