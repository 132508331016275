import { Form, Select } from "antd";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getCivilitesList } from "features/locataires/locatairesSlice";
import moment from "moment";
import { Civilites } from "pages/mainStep/type";
import { useEffect, useState } from "react";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import Autocomplete from "react-google-autocomplete";
import { emailIsUsed } from "features/assure/assureAPI";
import { fetchNationalityList } from "features/locataires/locataires";

  interface FormType {
    civilite: string;
    prenom: string;
    nom: string;
    email: string;
    telephone: string;
    address: string;
    city: string;
    codePostal: string;
    siret?: string;
    nomSociete?: string;
    googlePlaceId: string;
    latitude: string;
    longitude: string;
    adresseFormatee: string;
    additionalAddress: string;
    country: string;
    birthName?: string;
    birthCity?: string;
    birthCountry: string;
    nationality: string;
    taxResidenceCountry: string;
  }


  type Props = {
    onCancel: (pram:void) => void;
    onSubmit: (formData: any) => void;
    information: any;
    assure: {
        no: number,
        assure: any
    }
}

  const InformationForm: React.FC<Props> = ({onCancel, onSubmit, assure}) => {
    const [form] = Form.useForm();
    const [dataAddress, setDataAddress] = useState<any>({});
    const { civilites } = useAppSelector(
      (state: any) => state?.locatairesSlice
    );
    const dispatch = useAppDispatch()
    const [gender, setGender] = useState<string>(assure.assure?.information?.civilite);
    const [isEmprunteur, setIsEmprunteur] = useState<string>("")
    const [emailError, setEmailError] = useState<boolean>(false);
    const [nationalityList, setNationalityList] = useState<any[]>([]); 
    const [nationality, setNationality] = useState<any[]>([]); 

    const checkIfEmailIsValid = async (form: any) => {
      const res = await emailIsUsed(form?.email)
        setEmailError(res);
        return res;
    }


    const handleSubmit = async (form: any) => {
        if (isEmprunteur === "Non" && (form?.siret === "" || form?.nomSociete === ""))
            return ;


        const emailAlreadyTaken = await checkIfEmailIsValid(form);

        if (emailAlreadyTaken === true) {
          return ;
        }

        onSubmit({...form, ...dataAddress, isEmprunteur});
    }

    const getNationalitylist = async () => {
      const res: any = await fetchNationalityList();

      setNationalityList(res?.data?.items?.map((nationality: any) => {return {value: nationality?.id, label: nationality?.designation}}))
      if (!assure?.assure?.information?.nationality) {
          const frenchNationality = res?.data?.items?.find((nationality: any) =>  nationality?.code === "FR");
          form.setFieldValue("nationality", frenchNationality?.id);
      }
    }


    useEffect(() => {
      getNationalitylist();
      dispatch(getCivilitesList());
    }, [])

    const handleFormatPlace = (place: any) => {
        const listPlace = place?.address_components;
        const googlePlaceId = place?.place_id;
        const codePostal = listPlace?.find((x: any) =>
          x?.types?.includes("postal_code")
        )?.long_name;
        const country = listPlace?.find((x: any) =>
          x?.types?.includes("country")
        )?.long_name;
        const city = listPlace?.find((x: any) =>
          x?.types?.includes("locality")
        )?.long_name;
        const address = place?.formatted_address?.split(",")?.[0];
        geocodeByAddress(place?.formatted_address)
          .then((results) => getLatLng(results[0]))
          .then(({ lat, lng }) => {
            const dataAddress = {
              latitude: `${lat}`,
              longitude: `${lng}`,
              pays: country,
              ville: city,
              codePostal,
              adresseFormatee: address
                ? `${address},${city},${country}`
                : place?.formatted_address,
              googlePlaceId,
              ligne1: address,
            };
            setDataAddress(dataAddress);
    
            form.setFieldsValue({
              address: address,
              city: `${city}`,
              codePostal: codePostal,
              latitude: `${lat}`,
              longitude: `${lng}`,
              country: country,
              adresseFormatee: address
                ? `${address},${city},${country}`
                : place?.formatted_address,
              googlePlaceId: googlePlaceId,
              ligne1: address,
              additionalAddress: ""
            });
          });
      };

    return (
        <div className="w-100 h-100">
            <Form onFinish={handleSubmit} form={form} autoComplete="off"
                initialValues={{
                    civilite: assure.assure?.information?.civilite || "",
                    prenom: assure.assure?.information?.prenom || "",
                    nom: assure.assure?.information?.nom || "",
                    email:assure.assure?.information?.email || "",
                    telephone: assure.assure?.information?.telephone || "",
                    isEmprunteur: assure.assure?.information?.isEmprunteur || "",
                    dateNaissance: moment(assure.assure?.information?.dateNaissance).format("YYYY-MM-DD"),
                    nomSociete: assure.assure?.information?.nomSociete || "",
                    siret: assure.assure?.information?.siret || "",
                    address: assure?.assure?.information?.address || "",
                    codePostal: assure?.assure?.information?.codePostal || "",
                    additionalAddress: assure?.assure?.information?.additionalAddress || "",
                    city: assure?.assure?.information?.city || "",
                    adresseFormatee: assure?.assure?.information?.addressFormatee || "",
                    country: "France",
                    googlePlaceId: assure?.assure?.information?.googlePlaceId || "",
                    latitude: assure?.assure?.information?.latitude || "",
                    longitude: assure?.assure?.information?.longitude || "",
                    birthCountry: assure?.assure?.information?.birthCountry || "France", 
                    birthName: assure?.assure?.information?.birthName || "", 
                    birthCity: assure?.assure?.information?.birthCity || "", 
                    nationality: assure?.assure?.information?.nationality || "", 
                    taxResidenceCountry: assure?.assure?.information?.taxResidenceCountry || "France", 
                }}
            >
                <div className="mg-b20 formItem">
                  <p data-required="true">Civilité</p>
                  <Form.Item name="civilite"> 
                    <ul className="w-100 d-flex  radioGroup">
                      
                      {civilites?.map((x: Civilites, index: number) => (
                        <li
                          key={index}
                          className={`w-50 radius-4 cursor mg-r10 ${
                            gender === x?.id ? "activeLi" : ""
                          }`}
                          onClick={() => {setGender(x?.id); form.setFieldsValue({civilite: x?.id})}}
                        >
                          <span className="font-w600 font-14 lh-17">
                            {x?.libelle}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </Form.Item >
                </div>
                <div className="formItem">
                    <p data-required="true">Date de naissance</p>
                    <Form.Item name="dateNaissance" 
                    rules={[{ required: true, message: "Veuillez rentrer votre date de dateNaissance" }]}
                    >
                        <input
                            type="date"
                            className={`input`}
                            name="dateNaissance"
                        ></input>
                    </Form.Item>
                </div>
                <div className="formItem">
                    <p data-required="true">Nom</p>
                    <Form.Item name="nom" 
                    rules={[{ required: true, message: "Veuillez rentrer votre nom" }]}
                    >
                        <input
                            placeholder="Ex : Dupont"
                            className={`input`}
                            name="nom"
                        ></input>
                    </Form.Item>
                </div>
                <div className="formItem">
                    <p data-required="true">Prénom</p>
                    <Form.Item name="prenom" 
                    rules={[{ required: true, message: "Veuillez rentrer votre prénom" }]}
                    >
                        <input
                            placeholder="Ex : Pierre"
                            className={`input`}
                            name="prenom"
                        ></input>
                    </Form.Item>
                </div>
                <div className="formItem">
                    <p data-required="true">Adresse</p>
                    <Form.Item
                    name="address"
                    rules={[{ required: true, message: "Please input your city!" }]}
                    >
                      <Autocomplete
                          className={`input `}
                          id={"countryAnother" + Math.floor(Math.random() * 10000)}
                          placeholder="Adresse"
                          apiKey="AIzaSyCCelMiuDG-cQfWRb0KWDyPD_glL2hrwPM"
                          onPlaceSelected={(place) => {
                          handleFormatPlace(place);
                          }}
                          options={{
                          types: ["geocode", "establishment"],
                          componentRestrictions: { country: "fr" },
                          }}
                      />
                    </Form.Item>
                  </div>
                  <div className="formItem">
                        <p data-required="true">Ville</p>
                        <Form.Item
                        name="city"
                        rules={[{ required: true, message: "Please input your country!" }]}
                        >
                          <input placeholder="ville" 
                          className={`input `}
                          ></input>
                        </Form.Item>
                  </div>
                  <div className="formItem">
                        <p data-required="true">Code Postal</p>
                        <Form.Item
                        name="codePostal"
                        rules={[{ required: true, message: "Please input your postal code!" }]}
                        >
                          <input placeholder="codePostal" 
                          className={`input `}
                          ></input>
                        </Form.Item>
                  </div>
                <div className="formItem">
                    <p data-required="true">Adresse mail</p>
                    <Form.Item name="email" 
                    rules={[{ required: true, message: "Veuillez rentrer votre adresse mail" }]}
                    help={emailError ? 'Cet email est déjà pris.' : ''}
                    validateStatus={emailError ? 'error' : ''}
                    >
                        <input
                          type="email"
                            placeholder="Ex : pierre.dupont@mail.fr"
                            className={`input`}
                            name="email"
                        ></input>
                    </Form.Item>
                </div>
                <div className="formItem">
                    <p data-required="true">Numéro de téléphone</p>
                    <Form.Item name="telephone" 
                    rules={[{ required: true, message: "Veuillez rentrer votre téléphone" }]}
                    >
                        <input
                            placeholder="Ex : 06 XX XX XX XX XX"
                            className={`input`}
                            name="telephone"
                        ></input>
                    </Form.Item>
                </div>
                <div className="formItem">
                    <p>Nom de naissance</p>
                    <Form.Item name="birthName" 
                    >
                        <input
                            placeholder="Ex : Dupont"
                            className={`input`}
                            name="birthName"
                        ></input>
                    </Form.Item>
                </div>
                <div className="formItem">
                    <p>Ville de naissance</p>
                    <Form.Item name="birthCity" 
                    >
                        <input
                            placeholder="Ex : Paris"
                            className={`input`}
                            name="birthCity"
                        ></input>
                    </Form.Item>
                </div>
                <div className="formItem">
                    <p data-required="true">Pays de naissance</p>
                    <Form.Item name="birthCountry" 
                    rules={[{ required: true, message: "Veuillez rentrer votre téléphone" }]}
                    >
                        <input
                            placeholder="Ex : France"
                            className={`input`}
                            name="birthCountry"
                        ></input>
                    </Form.Item>
                </div>
                <div className="formItem">
                    <p data-required="true">Pays de la résidence fiscale</p>
                    <Form.Item name="taxResidenceCountry" 
                    rules={[{ required: true, message: "Veuillez rentrer votre téléphone" }]}
                    >
                        <input
                            placeholder="Ex : France"
                            className={`input`}
                            name="taxResidenceCountry"
                        ></input>
                    </Form.Item>
                </div>

                <div className={`formItem`}>
                    <Form.Item shouldUpdate={(prevValues, currentValues) => prevValues !== currentValues}>
                    {
                        () => {
                            return (
                              <Form.Item name="nationality" trigger="onChange">
                              <p data-required="true">Nationalité</p>
                              <Select dropdownStyle={{backgroundColor: "white"}} id="custom-select" onChange={(e) => form.setFieldValue("nationality", e)} value={form.getFieldValue("nationality")}>
                                  {
                                      nationalityList?.map((currenOption: any) => {
                                          return (
                                              <Select.Option value={currenOption?.value}>
                                                  <span>{currenOption?.label}</span>
                                              </Select.Option>
                                          )
                                      })
                                  }
                                
                                </Select>
                            </Form.Item>
                          )
                        }
                    }
                    </Form.Item>
                </div>
                
                <div className="formItem mg-t20 ">
                    <p data-required="true">L'assuré est-il l'emprunteur ?</p>
                    <ul className="w-100 d-flex mg-b20 radioGroup">
                      
                        <li
                          key={"isEmprunteurTrue"}
                          className={`w-50 radius-4 cursor mg-r10 ${
                            isEmprunteur === "Oui" ? "activeLi" : ""
                          }`}
                          onClick={() => {setIsEmprunteur("Oui");}}
                        >
                          <span className="font-w600 font-14 lh-17">
                            {"Oui"}
                          </span>
                        </li>
                        <li
                          key={"isEmprunteurFalse"}
                          className={`w-50 radius-4 cursor mg-r10  ${
                            isEmprunteur === "Non" ? "activeLi" : ""
                          }`}
                          onClick={() => {setIsEmprunteur("Non")}}
                        >
                          <span className="font-w600 font-14 lh-17">
                            {"Non"}
                          </span>
                        </li>
                    </ul>
                </div>
                {
                  isEmprunteur === "Non" && (
                    <>
                      <div className="formItem">
                        <p data-required="true">Nom de la société</p>
                        <Form.Item name="nomSociete" 
                        >
                            <input
                                placeholder="Nom de la société"
                                className={`input`}
                                name="nomSociete"
                            ></input>
                        </Form.Item>
                      </div>
                      <div className="formItem">
                        <p data-required="true">Numéro de SIRET</p>
                        <Form.Item name="siret" 
                        >
                            <input
                                placeholder="Siret"
                                className={`input`}
                                name="siret"
                            ></input>
                        </Form.Item>
                      </div>
                    </>

                  )
                }
                 <button className="submitButton" type="submit">
                        Valider mes informations
                  </button>
            </Form>
        </div>
    )
  }
  export default InformationForm