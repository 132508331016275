import { PageTransition } from "@steveeeie/react-page-transition"
import { Select } from "antd";
import FormItem from "antd/es/form/FormItem";
import { ActivitePrincipale, getActivitePrincipale, getListActivitesPrincipales, getListActivitesPrincipalesByParentId, getListActivitesPrincipalesParents } from "api/referentielsAPI";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { handleNext, selectDirection, selectDossierChoices, selectDossierIntermediateStep, setIntermediateStep } from "features/dossierSimuSlice";
import React, { useEffect, useState } from "react"

type Props = {
    handleChoice: (fieldName: string, value: boolean | string | number) => void;
}

const fetchActivites = async () => {
  const parents = await getListActivitesPrincipalesParents();
  return parents?.items?.map((currentParent: any) => {
    return {label: currentParent?.designation, value: currentParent?.id}
  })
};

const ActiviteProCBNO: React.FC<Props> = ({handleChoice}) => {
    const intermediateStep = useAppSelector(selectDossierIntermediateStep);
    const direction = useAppSelector(selectDirection);
    const dossierCBNO = useAppSelector(selectDossierChoices);
    const dispatch = useAppDispatch();

    const [optionsParent, setOptionsParent] = useState<any[]>([]);
    const [optionsEnfants, setOptionsEnfants] = useState<any[]>([]);
    const [valueParent, setValueParent] = useState<any>(null);
    const [valueEnfant, setValueEnfant] = useState<any>(dossierCBNO?.ActivitePrincipaleId);
  
    const getListEnfant = async (parentId: string) => {
      await getListActivitesPrincipalesByParentId(parentId).then((res) => {
        const optionsEnfants = res?.items?.map((currentParent: any) => {
          return {label: currentParent?.designation, value: currentParent?.id}
        })
        setOptionsEnfants(optionsEnfants);
      })
    }
  
    const onChangeParent = async (e: string | number) => {
      setValueParent(e);
      await getListEnfant(e as string);
    }
  
    const loadActivites = async () => {
      if (valueEnfant) {
        await getActivitePrincipale(valueEnfant).then((res: any) => {
          onChangeParent(res?.parentId);
        })
      }
  
      const activitesWithChildren = await fetchActivites();
      setOptionsParent(activitesWithChildren);
    };
    
    useEffect(() => {
      loadActivites();
    }, []);

    const handleSelection = async (value: any) => {
        setValueEnfant(value);
        const activity = await getActivitePrincipale(value);
    
        if (activity.acceptabiliteMRI) {
          handleChoice("ActivitePrincipaleId", activity?.id);
          dispatch(handleNext());
        }
        else {
          dispatch(setIntermediateStep(9))
        }
    }

    return (
        <PageTransition
          preset={direction}
          transitionKey={intermediateStep?.toString()}
          enterAnimation={""}
          exitAnimation={""}
        >
            <div className="question-container" id="activite-pro">
                <p className="mg-r5 mg-l5">
                    De quelle activité, l'usage profesionnel relève t-il ?
                </p>
                <div className="form-item w-85 ">
                    <FormItem name={"activitepro"}>
                    <p className='text-dark text-left mg-b20 font-16'>Choisissez la catégorie d'usage</p>
                    <Select dropdownStyle={{backgroundColor: "white"}} className="w-80" onChange={(e: any) => onChangeParent(e)} value={valueParent}>
                    {
                        optionsParent?.map((currentOption: any) => {
                            return (
                            <Select.Option value={currentOption?.value}>
                                <span>{currentOption?.label}</span>
                            </Select.Option>
                            )
                        })
                    }
                    </Select>
                    <p className='text-dark text-left mg-b20 mg-t30 font-16'>Choisissez la sous-catégorie d'usage</p>
                    <Select dropdownStyle={{backgroundColor: "white"}} className="w-80" onChange={(e: any) => handleSelection(e)} value={valueEnfant}>
                    {
                        optionsEnfants?.map((currentOption: any) => {
                            return (
                            <Select.Option value={currentOption?.value}>
                                <span>{currentOption?.label}</span>
                            </Select.Option>
                            )
                        })
                    }
                    </Select>
                    </FormItem>
                </div>
            </div>
         </PageTransition>
    )
}

export default ActiviteProCBNO;