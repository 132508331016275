import { API_SERVER } from "constants/configs";
import publicAPIInstance from "pages/connexion/publicProxy";

export function situationsList() {
  return new Promise((resolve, reject) => {
    try {
      const res = publicAPIInstance.get(
        `api/backoffice/referentiel/situations-professionnelles`
      );
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
}

export function typesList() {
  return new Promise((resolve, reject) => {
    try {
      const res = publicAPIInstance.get(
        `api/backoffice/referentiel/types-ressources`
      );
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
}

export function civilitesList() {
  return new Promise((resolve, reject) => {
    try {
      const res = publicAPIInstance.get(
        `api/backoffice/referentiel/civilites`
      );
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
}

export function fetchNationalityList() {
  return new Promise((resolve, reject) => {
    try {
      const res = publicAPIInstance.get(
        `api/backoffice/referentiel/nationalites/all`
      );
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
}

export function addLocataires(data: any) {
  return new Promise((resolve, reject) => {
    try {
      const res = publicAPIInstance.post(
        `api/simulations/gli/${data?.id}/locataires`,
        data?.data
      );
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
}

export function editLocatairesItem(data: any) {
  return new Promise((resolve, reject) => {
    try {
      const res = publicAPIInstance.put(
        `api/simulations/gli/${data?.id}/locataires`,
        data?.data
      );
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
}

export function deleteLocatairesItem(data: { id: string; idItem: string }) {
  return new Promise((resolve, reject) => {
    try {
      const res = publicAPIInstance.delete(
        `api/simulations/gli/${data?.id}/locataires/${data?.idItem}`
      );
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
}
