import React from "react";
import noOwner from "assets/image/common/man-fail.svg"

type Props = {
  isSide?: boolean
};

const IsNotEligible: React.FC<Props> = ({ isSide }) => {

  const onNouvelleSimu = () => {
    window.location.reload();
  }

  return (
    <div className={`${!isSide ? "max-w1300 mg-0-auto ccc-body-step-2" : ""}`} id="activite-pro">
      <div className="w-100 text-center border-box radius-15 cccbs2r1">
           <div className="text-center d-flex flex-direction-col flex-hor-center flex-ver-center">
            <img src={noOwner} className=""/>
            <p className="paragraphe-principale font-w700 w-80 mg-t20 mg-b20">
                Nous sommes désoles, votre copropriété n'est pas élibigle
            </p>
          </div>
          {
                <div className="d-flex flex-hor-center mg-t10">
                  <button
                    className="submitButton w-30"
                    onClick={() => onNouvelleSimu()}
                  >
                    Faire une nouvelle simulation
                  </button>
                </div>
            }
        </div>
      </div>
    );
};

export default IsNotEligible;
