import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { handleNext, selectDossierChoices, setIntermediateStep } from "features/dossierSimuSlice";
import { Select } from "antd";
import FormItem from "antd/es/form/FormItem";
import { ActivitePrincipale, getActivitePrincipale, getListActivitesPrincipales, getListActivitesPrincipalesByParentId, getListActivitesPrincipalesParents } from "api/referentielsAPI";

type Props = {
  handleOnClick: (
    fieldName: string,
    value: boolean | string | any
  ) => void;
  isSide?: boolean
};

const fetchActivites = async () => {
  const parents = await getListActivitesPrincipalesParents();
  return parents?.items?.map((currentParent: any) => {
    return {label: currentParent?.designation, value: currentParent?.id}
  })
};

const ActivitePro: React.FC<Props> = ({ handleOnClick, isSide }) => {
  const mri = useAppSelector(selectDossierChoices);
  const dispatch = useAppDispatch();

  const [optionsParent, setOptionsParent] = useState<any[]>([]);
  const [optionsEnfants, setOptionsEnfants] = useState<any[]>([]);
  const [valueParent, setValueParent] = useState<any>(null);
  const [valueEnfant, setValueEnfant] = useState<any>(mri?.ActivitePrincipaleId);

  const getListEnfant = async (parentId: string) => {
    await getListActivitesPrincipalesByParentId(parentId).then((res) => {
      const optionsEnfants = res?.items?.map((currentParent: any) => {
        return {label: currentParent?.designation, value: currentParent?.id}
      })
      setOptionsEnfants(optionsEnfants);
    })
  }

  const onChangeParent = async (e: string | number) => {
    setValueParent(e);
    await getListEnfant(e as string);
  }

  const loadActivites = async () => {
    if (valueEnfant) {
      await getActivitePrincipale(valueEnfant).then((res: any) => {
        onChangeParent(res?.parentId);
      })
    }

    const activitesWithChildren = await fetchActivites();
    setOptionsParent(activitesWithChildren);
  };
  
    useEffect(() => {
      loadActivites();
    }, []);

  useEffect(() => {
    if (mri && mri?.firstElements) {
      const isCommerces = mri?.firstElements.find((el: any) => el.label === "Commerces").value
      if (!isCommerces) {
        dispatch(handleNext())
      }
    }
  }, [mri])

  const handleNextStepCheck = async (choiceActivity : any) => {
    const activity = await getActivitePrincipale(choiceActivity);

    if (activity.acceptabiliteMRI) {
      handleOnClick("ActivitePrincipaleId", activity?.id);
      dispatch(handleNext());
    }
    else {
      dispatch(setIntermediateStep(15))
    }
  }

  return (
    <div className={`${!isSide ? "max-w1300 mg-0-auto ccc-body-step-2" : ""}`} id="activite-pro">
      <div className="w-100 text-center border-box radius-15 cccbs2r1">
        {
          <div className="mg-b50">
              <>
                <div>
                  <p className="font-18 lh-22 text-dark font-w700 cccbs2r1r1">
                      De quelle activité, l'usage profesionnel relève t-il ?
                  </p>
                </div>
              </>
          </div>
        }
        <div
          className={`${!isSide ? "d-flex flex-hor-center cccbs2r1r2" : "d-flex flex-hor-start"} w-100`}
        >
            <div className="form-item w-80 ">
                <FormItem name={"activitepro"}>

                  <p className='text-dark text-left mg-b20 font-16'>Choisissez la catégorie d'usage</p>
                  <Select dropdownStyle={{backgroundColor: "white"}} className="w-80" onChange={(e: any) => onChangeParent(e)} value={valueParent}>
                  {
                      optionsParent?.map((currentOption: any) => {
                          return (
                          <Select.Option value={currentOption?.value}>
                              <span>{currentOption?.label}</span>
                          </Select.Option>
                          )
                      })
                  }
                  </Select>
                  <p className='text-dark text-left mg-b20 mg-t30 font-16'>Choisissez la sous-catégorie d'usage</p>
                  <Select dropdownStyle={{backgroundColor: "white"}} className="w-80" onChange={(e: any) => setValueEnfant(e)} value={valueEnfant}>
                  {
                      optionsEnfants?.map((currentOption: any) => {
                          return (
                          <Select.Option value={currentOption?.value}>
                              <span>{currentOption?.label}</span>
                          </Select.Option>
                          )
                      })
                  }
                  </Select>
                </FormItem>
          </div>
        </div>
      </div>
      {
            <div className="d-flex flex-hor-end mg-t10">
              <button
                className="submitButton w-30"
                onClick={() => handleNextStepCheck(valueEnfant)}
                disabled={valueEnfant === null}
              >
                Étape suivante
              </button>
            </div>
        }
    </div>
  );
};

export default ActivitePro;
