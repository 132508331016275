import { useAppSelector } from "app/hooks";
import {  selectDossierStepActive } from "features/dossierSimuSlice";

import StepOne from "features/MRI/stepOne";
import StepTwo from "features/components/choiceGarantie/stepTwo";
import Information from "features/commonSteps/information";
import SimulationTerminee from "features/commonSteps/simulationTerminee";



const MRISimu: React.FC = () => {

    const activeStep = useAppSelector(selectDossierStepActive);

    return (
        <>
        {(() => {
            switch (activeStep) {
                case 0:
                    return <StepOne></StepOne>
                case 1:
                    return (
                      <div className="max-w1300 mg-0-auto ccc-body-step-2 h-90 d-flex flex-hor-center flex-ver-center">
                        <div className="w-100 text-center border-box radius-15 cccbs2r1 overflow-mri-garantie">
                          <StepTwo/>
                        </div>
                      </div>
                    )
                case 2:
                  return (
                    <div className="max-w1300 mg-0-auto ccc-body-step-2 h-90 d-flex flex-hor-center flex-ver-center">
                        <div className="w-100 text-center border-box radius-15 cccbs2r1 overflow-mri-garantie">
                          <Information type="MRI"/>
                        </div>
                    </div>
                  )
                case 3:
                  return (
                    <div className="max-w1300 mg-0-auto ccc-body-step-2 h-90 d-flex flex-hor-center flex-ver-center">
                        <div className="w-100 text-center border-box radius-15 cccbs2r1 overflow-mri-garantie">
                            <SimulationTerminee />
                        </div>
                    </div>
                  )
            }
        })()}
    </>
    );

}

export default MRISimu