import { useAppSelector } from "app/hooks"
import Stepper from "features/components/stepper/stepper"
import { selectShowHeader } from "features/dossierSimuSlice"
import { useIsSmallScreen } from "hooks/isSmallScreen"
import React, { ReactNode } from "react"

type Props = {
    children: ReactNode
}

const SimuLayout: React.FC<Props> = ({children}) => {

    const modalOpen = useAppSelector(selectShowHeader);
    const isSmallScreen = useIsSmallScreen(567);

    return (
        <div className="container-simu">
            <Stepper />
            <div className={`${!modalOpen && isSmallScreen ? "simu-modal" : "simu-form-container"} h-100`}>
                <div className="simu-form">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default SimuLayout

