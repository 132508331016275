import { FormInstance, Select } from "antd"
import FormItem from "antd/es/form/FormItem";

type Props = {
    form: FormInstance;
}

const AmortissableForm: React.FC<Props> = ({form}) => {
    return (
        <>
            <div className="text-left">
                <p className="mg-b10" data-required="true">Taux d'emprunt</p>
                <div className="d-flex  flex-ver-center">
                    <div className="formItem w-45 mg-r10">
                        <FormItem name="natureTaux"
                            rules={[
                            { required: true, message: "Veuillez rentrer la nature du taux" },
                            ]}
                        >
                                    <Select dropdownStyle={{backgroundColor: "white"}} id="custom-select">
                                        <Select.Option value={"Fixe"}>
                                            <span >Fixe</span>
                                        </Select.Option>
                                        <Select.Option value={"Variable"}>
                                            <span >Variable</span>
                                        </Select.Option>
                                    </Select>
                        </FormItem>
                    </div>
                    <div className="formItem w-50">
                        <FormItem name="pourcentageTaux" 
                            rules={[
                                { required: true, message: "Veuillez rentrer le pourcentage du taux" },
                                { pattern: /^\d+$/, message: "Veuillez entrer uniquement des chiffres" }
                            ]}
                        >
                            <input
                                type="number"
                                className={`input`}
                                name="pourcentageTaux"
                                placeholder="Pourcentage du taux"
                            ></input>
                        </FormItem>
                    </div>
                </div>
            </div>
            <div className="formItem">
                <p data-required="true">Durée totale du prêt avec differé</p>
                <FormItem name="dureeTotale" 
                    rules={[
                        { required: true, message: "Veuillez rentrer la duree totale du pret" },
                        { pattern: /^\d+$/, message: "Veuillez entrer uniquement des chiffres" }
                        ]}
                >
                    <input
                        type="number"
                        className={`input`}
                        name="dureeTotale"
                        placeholder="Duree totale du pret"
                    ></input>
                </FormItem>
            </div>
            <div className="formItem">
                <p data-required="true">Différé (en mois)</p>
                <FormItem name="differe" 
                    rules={[
                        { required: true, message: "Veuillez rentrer la duree du differé" },
                        { pattern: /^\d+$/, message: "Veuillez entrer uniquement des chiffres" }
                        ]}
                >
                    <input
                        type="number"
                        className={`input`}
                        name="differe"
                        placeholder="Duree du differé"
                    ></input>
                </FormItem>
            </div>
        </>
    )
}
export default AmortissableForm