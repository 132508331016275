import publicAPIInstance from "pages/connexion/publicProxy"
import { ListResponse } from "types/main"

export type ActivitePrincipale = {
    id: string,
    code: string,
    designation: string,
    acceptabiliteMRI: boolean,
    parentId: string | null,
    children: ActivitePrincipale[] | null
}

export async function getListActivitesPrincipalesParents() : Promise<ListResponse<ActivitePrincipale>> {
    return (
      await publicAPIInstance.get(
        `/api/backoffice/referentiel/activites-principales/parents`
      )
    )?.data
}

export async function getListActivitesPrincipalesByParentId(parentId: string) : Promise<ListResponse<ActivitePrincipale>> {
    return (
      await publicAPIInstance.get(
        `/api/backoffice/referentiel/activites-principales/by-parent-id?parentId=${parentId}`
      )
    )?.data
}

export async function getListActivitesPrincipales() : Promise<ListResponse<ActivitePrincipale>> {
    return (
      await publicAPIInstance.get(
        `/api/backoffice/referentiel/activites-principales/all`
      )
    )?.data
}

export async function getActivitePrincipale(id: string) : Promise<ActivitePrincipale> {
    return (
      await publicAPIInstance.get(
        `/api/backoffice/referentiel/activites-principales?id=${id}`
      )
    )?.data
}