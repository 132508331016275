import home from "assets/image/emprunteur/home (2).svg"
import vacances from "assets/image/emprunteur/Groupe 2856.svg"
import maisonCle from "assets/image/emprunteur/Groupe 2858.svg"
import travaux from "assets/image/emprunteur/travaux.svg"
import commerces from "assets/image/emprunteur/commerces.svg"
import racImmo from "assets/image/emprunteur/racImmo.svg"
import { useState } from "react"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { selectDossierChoices, setDossierChoices } from "features/dossierSimuSlice"
import { useIsSmallScreen } from "hooks/isSmallScreen"

type Props = {
    onCancel: () => void;
}

const SituationEmprunteurEdit: React.FC<Props> = ({onCancel}) => {
    const dossierChoices = useAppSelector(selectDossierChoices);
    const [selectedSituation, setSelectedSituation] = useState<string>(dossierChoices?.Situation);
    const [selectedProjet, setSelectedProjet] = useState<string>(dossierChoices?.Projet);
    const isSmallScreen = useIsSmallScreen(567);
    const dispatch = useAppDispatch();

    const submitEditSituation = () => {
        const payload = {
            dossierChoices : {
            Projet: selectedProjet,
            Situation: selectedSituation
            },
            type: "EMP"
        };
        dispatch(setDossierChoices(payload));
        onCancel();
    }

    return (
        <div className="" id={"emprunteur-modal"}>
            <div className="question-container">
                <p>Quelle situation correspond à votre projet d'assurance emprunteur ?</p>
                <button onClick={() => setSelectedSituation("Nouveau prêt")}
                    className={`w-100 question-button mg-top  ${selectedSituation === "Nouveau prêt" ? "active" : ""}`}
                >
                    <img src={home} alt="Home"></img>
                    <p className={`text-left `}>Nouveau prêt</p>
                </button>
                <button onClick={() => setSelectedSituation("Renégociation banque")}
                    className={`w-100 question-button mg-top  ${selectedSituation === "Renégociation banque" ? "active" : ""}`}
                >
                    <img src={vacances} alt="Tongues"></img>
                    <p className={`text-left `}>Renégociation d'assurance (banque)</p>
                </button>
                <button onClick={() => setSelectedSituation("Renégociation courtier")} 
                    className={`w-100 question-button mg-top  ${selectedSituation === "Renégociation courtier" ? "active" : ""}`}
                >
                    <img src={maisonCle} alt="Home"></img>
                    <p className={`text-left `}>Renégociation d'assurance (courtier)</p>
                </button>
                <button onClick={() => setSelectedSituation("Ne sais pas")}
                    className={`w-100 question-button mg-top  ${selectedSituation === "Ne sais pas" ? "active" : ""}`}
                >
                    <img src={travaux} alt="Home"></img>
                    <p className={`text-left `}>Je ne sais pas</p>
                </button>
            </div>
            <div className={`question-container mg-t20`}>
                    <p className="font-18 text-dark">Décrivez-nous votre projet de crédit</p>
                    <button onClick={() => setSelectedProjet("Residence principale")} 
                        className={`w-100 question-button mg-top  ${selectedProjet === "Residence principale" ? "active" : ""}`}
                    >
                        <img src={home} alt="Home"></img>
                        <p className={`text-left `}>Résidence principale</p>
                    </button>
                    <button onClick={() => setSelectedProjet("Résidence secondaire")} 
                        className={`w-100 question-button mg-top  ${selectedProjet === "Résidence secondaire" ? "active" : ""}`}
                    >
                        <img src={vacances} alt="Tongues"></img>
                        <p className={`text-left `}>Résidence secondaire</p>
                    </button>
                    <button onClick={() => setSelectedProjet("Achat locatif")}
                        className={`w-100 question-button mg-top  ${selectedProjet === "Achat locatif" ? "active" : ""}`}
                    >
                        <img src={maisonCle} alt="Home"></img>
                        <p className={`text-left `}>Achat locatif</p>
                    </button>
                    <button onClick={() => setSelectedProjet("Travaux")}
                        className={`w-100 question-button mg-top  ${selectedProjet === "Travaux" ? "active" : ""}`}
                    >
                        <img src={travaux} alt="Home"></img>
                        <p className={`text-left `}>Travaux</p>
                    </button>
                    <button onClick={() => setSelectedProjet("Crédit consommation")}
                        className={`w-100 question-button mg-top  ${selectedProjet === "Crédit consommation" ? "active" : ""}`}
                    >
                        <img src={commerces} alt="Home"></img>
                        <p className={`text-left`}>Crédit à la consommation</p>
                    </button>
                    <button onClick={() => setSelectedProjet("Rac Immo")}
                        className={`w-100 question-button mg-top  ${selectedProjet === "Rac Immo" ? "active" : ""}`}
                    >
                        <img src={racImmo} alt="Home"></img>
                        <p className={`text-left`}>RAC Immo</p>
                    </button>
                </div>
                <div className="w-60 d-flex buttonGroup flex-hor-center flex-ver-center mg-t10">
                    <button className="submitButton" onClick={submitEditSituation}>
                        Valider
                    </button>
                </div>
        </div>
    )
}
export default SituationEmprunteurEdit