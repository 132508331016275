import { useAppDispatch, useAppSelector } from "app/hooks";
import Question from "features/components/question/question";
import { selectDirection, selectDossierChoices, selectDossierIntermediateStep, selectDossierType, setDossierChoices, setType } from "features/dossierSimuSlice";
import React from "react"
import ChoiceBien from "./choiceBien/choiceBien";
import { PageTransition } from "@steveeeie/react-page-transition";
import NonEligible from "./nonEliglible/nonEligible";
import Eligible from "./eligible/eligible";
import ActiviteProCBNO from "./activitePro";

const StepOne: React.FC = () => {

    const intermediateStep = useAppSelector(selectDossierIntermediateStep);
    const direction = useAppSelector(selectDirection);
    const dispatch = useAppDispatch();
    const dossierCBNO = useAppSelector(selectDossierChoices);

    const handleChoice = (
        fieldName: string,
        value: boolean | string | number
        ) => {
        const payload = {
            dossierChoices : {
                [fieldName]: value,
              }
        }
        dispatch(setType("cbno"));
        dispatch(setDossierChoices(payload));
    }

    const choseComponentToRender = () => {
        if (intermediateStep === 9) {
            return <NonEligible></NonEligible>
        }
        else if (intermediateStep === 10) {
            return <Eligible></Eligible>
        } else if (intermediateStep === 1) {
            return <ActiviteProCBNO handleChoice={handleChoice}/>
        }
        else {
            return <Question handleChoice={handleChoice}/>
        }
    }

    return (
        <PageTransition
        preset={direction}
        transitionKey={intermediateStep?.toString()}
        enterAnimation={""}
        exitAnimation={""}
      >
        {
            intermediateStep != 4 ? (
            <>  
            {
                choseComponentToRender()
            }
            </>)
            : 
            <ChoiceBien />
        }
        </PageTransition>
    );
}

export default StepOne;