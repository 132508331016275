import {ReactComponent as Home} from "assets/image/emprunteur/home (2).svg"
import edit from "assets/image/infoStepFour/edit.png"
import trash from "assets/image/infoStepFour/remove.svg"
import { ReactNode, useState } from "react"
import {ReactComponent as Plus} from "assets/image/emprunteur/plus-blue.svg"
import { useNaturePret } from "hooks/useNaturePret"
import { useAppDispatch } from "app/hooks"
import { ReactComponent as Paperclip } from 'assets/image/emprunteur/paperclip.svg';
import PretForm from "./pretForm"

type Props = {
    pret: any,
    file: {
        amortissement: any,
        offre: any
    },
    setFiles: (values: any, type: string) => void;
    setModalContent: (content: ReactNode) => void,
    setIsModalOpen: (value: boolean) => void
}

const PretEmprunteur: React.FC<Props> = ({pret, file, setFiles, setIsModalOpen, setModalContent}) => {

    const [currentPretIndex, setCurrentPretIndex] = useState<number>(0);
    const listNaturePret = useNaturePret();
    const dispatch = useAppDispatch();


    function getSideMenuContent(index: number) {
        const pretExiste = index >= 0 && index < (pret?.length || 0);
        const currentPret = pretExiste ? pret[index] : null;
        let label: string =  (index + 1).toString();
        if (pretExiste) {
            label = label + ": " + currentPret?.montantPret + "€";
        }

        setModalContent(
            <PretForm onCancel={() => setIsModalOpen(false)} currentPret={{no: index + 1, pret: currentPret, label: label}}/>
        )
        setIsModalOpen(true);
    }

    const deletePret = (index: number) => {
        const updatedPrets = [...pret];
        updatedPrets.splice(index, 1);
        // dispatch(setPrets(updatedPrets));
    }

    const handleClickUploadFile = (type: string) => {
        const uploadFile = document.getElementById(type === "amortissement" ?'uploadFileAmortiss' : 'uploadFilePret');
        uploadFile?.click();
    };
      
    const handleUploadFile = (e: React.ChangeEvent<HTMLInputElement>, type:string) => {
        const file = e?.target?.files?.[0] as File;
        setFiles(file, type);
    };

    return (
        <div className="emprunteur-container w-90 mg-t30 mg-b20">
        <div className="title-container w-100 flex-hor-between flex-ver-center">
            <div className="d-flex">
                <Home className="svg-primary mg-r10" style={{height: "20px", width: "20px"}}/>
                <p>Les prêts</p>
            </div>
        </div>
                {
                pret?.length >= 1 ?
                    <div className="assure-map">
                        {
                            pret.map((currentPret: any, index: number) => {
                            return (
                                <div className="assure-content" key={"prets" + index}>
                                    <div>
                                        <p>Prêt {listNaturePret?.find((pret: any) => pret?.id === currentPret?.naturePret)?.libelle} de {currentPret.montantPret} €</p>
                                        <p>{currentPret?.montantAssurance ? (`Assurance actuelle: ${currentPret.montantAssurance || 0} €`) : "Assurance actuelle: Non renseignée"}</p>
                                    </div>
                                    <div className="d-flex">
                                        <img src={edit} alt="edit"  onClick={() => {
                                            setCurrentPretIndex(index);
                                            getSideMenuContent(index);
                                        }}/>
                                        <img src={trash} alt="trash" style={{ transform: 'translateX(-30px)' }} onClick={() => {
                                            deletePret(index)
                                        }}/>
                                    </div>
                                </div>
                        )})}
                        <div className="assure-content" onClick={() => {
                            setCurrentPretIndex(pret.length);
                            getSideMenuContent(pret.length);
                        }}>
                            <div className="ajout-asssure-container">
                                <p className="text-blue">
                                    Ajouter un pret
                                </p>
                                <Plus className="svg-primary"/>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="assure-container">

                        <div className="ajout-assure" onClick={() => {
                            setCurrentPretIndex(0);
                            getSideMenuContent(0);
                        }}>
                            <Plus className="svg-primary"/>
                            <p className="text-blue">
                                Ajouter un pret
                            </p>
                        </div>
                    </div>
                }
    </div>
    )
}

export default PretEmprunteur