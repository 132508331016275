import house from "assets/image/startStep/house.svg";
import { setBienId, setInfoUserAction } from "pages/mainStep/mainSlice";
import { Form, Upload } from "antd";
import { useEffect, useState } from "react";
import { getBase64 } from "../../../helpers/getBase64";
import upload from "assets/image/startStep/upload.svg";
import Autocomplete from "react-google-autocomplete";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import { simulationInfoAction } from "../monBienSlice";
import { getMinMaxAction } from "../monBienSlice";
import { setMoneyAction } from "pages/mainStep/mainSlice";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { handleJustify } from "helpers/handleJustify";
import close from "assets/image/mainStep/close.svg";
import { uploadImage } from "pages/mainStep/mainStepAPI";
import { Spin } from "antd";

interface FormType {
  address: string;
  anotherAddress: string;
  nomDuBien: string;
  nombreDePieces: string;
  surface: string;
  ville: string;
  url: string;
  montantCharges: string;
}

const MonBienStepTwo = ({ handleNextStep }: any) => {
  const [url, setURL] = useState<string>();
  const [value, setValue] = useState(0);
  const [dataAddress, setDataAddress] = useState({});
  const [photoId, setPhotoId] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const { infoUser, simulationId, money } = useAppSelector(
    (state: any) => state.mainSlice
  );
  const { minMax } = useAppSelector((state) => state.monBienSlice);

  useEffect(() => {
    if (infoUser) {
      form.setFieldsValue(infoUser);
      setDataAddress(infoUser?.dataAddress);

      const input = document.querySelector(
        'input[type="range"]'
      ) as HTMLInputElement;
      setValue(Number(infoUser?.price || 0));
      input.style.backgroundSize =
        ((Number(infoUser?.price || 0) - 0) * 100) / 1000 + "% 100%";

      if (infoUser?.url) {
        setURL(infoUser?.url);
      }
    }
  }, [infoUser, dispatch, form]);

  useEffect(() => {
    const newValue =
      Number(money) === 0 ? minMax?.montantLoyerMinimal || 0 : Number(money);
    setValue(newValue);
    const input = document.querySelector(
      'input[type="range"]'
    ) as HTMLInputElement;
    input.style.backgroundSize = !simulationId
      ? "0% 100%"
      : ((newValue - minMax?.montantLoyerMinimal) * 100) /
          (minMax?.montantLoyerMaximal - minMax?.montantLoyerMinimal) +
        "% 100%";
  }, [money, minMax, simulationId]);

  useEffect(() => {
    if (simulationId) {
      dispatch(getMinMaxAction(simulationId));
    }
  }, [dispatch, simulationId]);

  const handleChangeImage = async (img: any) => {
    setLoading(true);
    const data = new FormData();
    data.append("content", img?.file?.originFileObj);

    const res: any = await uploadImage(simulationId, data);
    if (res?.data?.fileId) {
      setLoading(false);
      setPhotoId(res?.data?.fileId);
      getBase64(img?.file?.originFileObj, (base64: string) => {
        setURL(base64);
      });
    } else {
      setLoading(false);
    }
  };

  const handleSubmit = async (form: FormType) => {
    setLoading(true);
    const dataSubmit: any = {
      adresse: { ...dataAddress, ligne2: form?.anotherAddress },
      nomDuBien: form?.nomDuBien,
      nombreDePieces: Number(form?.nombreDePieces),
      surface: Number(form?.surface),
      montantLoyer: Number(value),
      montantCharges: Number(form?.montantCharges),
    };

    if (photoId) {
      dataSubmit.photoDuBienId = photoId;
    }

    const saveData = { ...form, price: value, dataAddress };
    if (url) {
      saveData.url = url;
    }
    dispatch(setMoneyAction(value));
    const response = await dispatch(
      simulationInfoAction({ id: simulationId, data: dataSubmit })
    );
    if (response?.error?.message !== "Rejected") {
      setLoading(false);
      setTimeout(() => {
        dispatch(setBienId(response.payload.id))
        dispatch(setInfoUserAction(saveData));
      }, 500);
      handleNextStep();
    } else {
      setLoading(false);
    }
  };

  const handleSetValue = (value: number) => {
    setValue(value);
    const input: any = document.querySelector('input[type="range"]');
    input.style.backgroundSize =
      ((value - minMax?.montantLoyerMinimal) * 100) /
        (minMax?.montantLoyerMaximal - minMax?.montantLoyerMinimal) +
      "% 100%";
  };

  const handleFormatPlace = (place: any) => {
    const listPlace = place?.address_components;
    const googlePlaceId = place?.place_id;
    const codePostal = listPlace?.find((x: any) =>
      x?.types?.includes("postal_code")
    )?.long_name;
    const country = listPlace?.find((x: any) =>
      x?.types?.includes("country")
    )?.long_name;
    const city = listPlace?.find((x: any) =>
      x?.types?.includes("locality")
    )?.long_name;
    const address = place?.formatted_address?.split(",")?.[0];
    geocodeByAddress(place?.formatted_address)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        const dataAddress = {
          latitude: `${lat}`,
          longitude: `${lng}`,
          pays: country,
          ville: city,
          codePostal,
          adresseFormatee: address
            ? `${address},${city},${country}`
            : place?.formatted_address,
          googlePlaceId,
          ligne1: address,
        };
        setDataAddress(dataAddress);

        form.setFieldsValue({
          address: address,
          ville: `${city} (${codePostal})`,
        });
      });
  };

  useEffect(() => {
    handleJustify("monBienStepTwo");
  }, [url]);

  useEffect(() => {
    window.addEventListener("resize", () => handleJustify("monBienStepTwo"));

    return () =>
      window.removeEventListener("resize", () =>
        handleJustify("monBienStepTwo")
      );
  }, []);

  return (
    <div id="monBienStepTwo">
      <img src={house} alt="loading" className="houseImage"></img>
      <h2>Votre bien à louer</h2>
      <Form onFinish={handleSubmit} form={form} autoComplete="off">
        <div className="formItem">
          <p data-required="true">Nommez votre bien</p>
          <Form.Item 
            name="nomDuBien"
            rules={[{ required: true, message: "Merci de renseigner un intitulé !" }]}
          >
            <input
              placeholder="Ex : Appartement rue blablabla"
              className="input"
              name="name"
            ></input>
          </Form.Item>
        </div>
        <div className="formItem">
          <p data-required="true">Adresse</p>
          <Form.Item
            name="address"
            rules={[{ required: true, message: "Merci de renseigner votre adresse !" }]}
          >
            <Autocomplete
              className="input"
              id={"countryAnother" + Math.floor(Math.random() * 10000)}
              placeholder="Adresse"
              apiKey="AIzaSyCCelMiuDG-cQfWRb0KWDyPD_glL2hrwPM"
              onPlaceSelected={(place) => {
                handleFormatPlace(place);
              }}
              options={{
                types: ["geocode", "establishment"],
                componentRestrictions: { country: "fr" },
              }}
            />
          </Form.Item>
        </div>
        <div className="formItem">
          <p data-required="true">Ville</p>
          <Form.Item
            name="ville"
            rules={[{ required: true, message: "Merci de renseigner votre ville !" }]}
          >
            <input placeholder="ville" className="input"></input>
          </Form.Item>
        </div>

        <div className="formItem">
          <p>Complément d'adresse</p>
          <Form.Item name="anotherAddress">
            <input placeholder="Complément d'adresse" className="input"></input>
          </Form.Item>
        </div>
        <Upload
          onChange={handleChangeImage}
          showUploadList={false}
          style={{ width: "100%" }}
          disabled={loading}
        >
          <div className="upload">
            {loading ? (
              <Spin className="p-absolut"></Spin>
            ) : (
              <p>Ajouter une photo (facultatif)</p>
            )}
            {!loading && <img alt="loading" src={upload}></img>}
          </div>
        </Upload>
        {url && (
          <div className="profileImgWrap">
            <img className="profileImg" src={url} alt="avatar" />
            <img
              className="removeImage"
              src={close}
              alt="close"
              onClick={() => {
                setPhotoId("");
                setURL("");
              }}
            />
          </div>
        )}

        <div className="inputRangeGroup">
          <div>
            <h6 data-required="true">Montant du loyer</h6>
            <p>
              {value}€ <span>/ mois hors charge</span>
            </p>
          </div>
          <input
            type="range"
            min={minMax?.montantLoyerMinimal || 0}
            max="3000"
            onChange={(e: any) => handleSetValue(e.target.value)}
            value={value}
            disabled={!simulationId && !minMax}
          ></input>
        </div>
        <div className="inputGroup">
          <div className="formItem">
            <p data-required="true">Nombre de pièces</p>
            <Form.Item
              name="nombreDePieces"
              rules={[{ required: true, message: "Merci de renseigner le nombre de pièces!" }]}
            >
              <input
                placeholder="Nombre de pièces"
                min="0"
                className="input"
                type="number"
                onKeyDown={(e) =>
                  (e.keyCode === 69 ||
                    e.keyCode === 190 ||
                    e.keyCode === 189) &&
                  e.preventDefault()
                }
              ></input>
            </Form.Item>
          </div>

          <div className="formItem">
            <p data-required="true">Nombre de mètres carrés</p>
            <Form.Item
              name="surface"
              rules={[{ required: true, message: "Merci de renseigner le nombre de mètres carrés !" }]}
            >
              <input
                placeholder="Nombre de mètres carrés"
                className="input"
                type="number"
                min="0"
                onKeyDown={(e) =>
                  (e.keyCode === 69 ||
                    e.keyCode === 190 ||
                    e.keyCode === 189) &&
                  e.preventDefault()
                }
              ></input>
            </Form.Item>
          </div>
        </div>
        <div className="formItem">
          <p data-required="true">Montant charges</p>
          <Form.Item
            name="montantCharges"
            rules={[{ required: true, message: "Merci de renseigner le montant des charges !" }]}
          >
            <input
              placeholder="Montant des charges"
              className="input"
              type="number"
              min="0"
              onKeyDown={(e) =>
                (e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 189) &&
                e.preventDefault()
              }
            ></input>
          </Form.Item>
        </div>

        <button className="submitButton" type="submit" disabled={loading}>
          Valider mon bien
        </button>
      </Form>
    </div>
  );
};

export default MonBienStepTwo;
