import classNames from "classnames";
import { useEffect } from "react";
import { setFamilyAction } from "pages/mainStep/mainSlice";
import { useNavigate } from "react-router-dom";
import plus from "assets/image/locataire/plus.svg";
import clock from "assets/image/stepTwo/clock.svg";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import remove from "assets/image/stepTwo/remove.svg";
import { useState } from "react";
import { TypeList } from "pages/mainStep/type";
import {
  LOCATAIRES3STEP6,
  LOCATAIRES3STEP4,
  LOCATAIRES_LABEL,
  LOCATAIRES3STEP5,
} from "pages/mainStep/routeList";
import { handleNextStep } from "helpers/handleNextStep";

const ItemThree = ({ setShow, setValueList, valueList, garant }: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { listFamily, indexFamily, routeList } = useAppSelector(
    (state: any) => state?.mainSlice
  );
  const { typesList } = useAppSelector((state) => state?.locatairesSlice);

  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (indexFamily >= 0) {
      let data: any;
      if (!garant) {
        data = listFamily?.[indexFamily]?.step3;
      } else {
        data = listFamily?.[indexFamily]?.step3Garant;
      }
      if (data?.length > 0) {
        setValueList(data);
        setTimeout(() => {
          const input = document.querySelector(
            'input[type="range"]'
          ) as HTMLDivElement;
          if (input) {
            input.style.backgroundSize =
              ((Number(data?.[0]?.value) - 0) * 100) / 1000 + "% 100%";
          }
        });
      } else {
        setValueList([
          {
            ...typesList?.find((x: TypeList) => x?.code === "Salaire"),
            value: 0,
          },
        ]);
      }
    }
  }, [indexFamily, garant]);

  const handleChangeNumber = (value: number, index: number) => {
    setValueList(
      valueList?.map((x: TypeList, indexMap: number) => {
        if (indexMap === index) {
          return { ...x, value };
        } else {
          return x;
        }
      })
    );
  };

  const handleSubmit = () => {
    const newListFamily = [...listFamily];
    if (!garant) {
      newListFamily[indexFamily] = {
        ...newListFamily[indexFamily],
        step3: valueList,
      };
      dispatch(setFamilyAction(newListFamily));

      const isGarant =
        listFamily?.[indexFamily]?.step2?.children?.peutEtreAttribueGarant ||
        listFamily?.[indexFamily]?.step2?.parent?.peutEtreAttribueGarant;
      if (!isGarant) {
        const newRouteList = routeList?.map((x: any) => {
          if (x?.label === LOCATAIRES_LABEL) {
            return {
              ...x,
              list: x?.list?.filter(
                (y: string) =>
                  !y?.includes(LOCATAIRES3STEP4) &&
                  !y?.includes(LOCATAIRES3STEP5)
              ),
            };
          } else {
            return x;
          }
        });
        navigate(handleNextStep(newRouteList));
      } else {
        navigate(handleNextStep(routeList));
      }
    } else {
      newListFamily[indexFamily] = {
        ...newListFamily[indexFamily],
        step3Garant: valueList,
      };
      dispatch(setFamilyAction(newListFamily));
      navigate(handleNextStep(routeList));
    }
  };

  const handleSetValue = (value: number, index: number) => {
    handleChangeNumber(value, index);
    const input = document.querySelector(
      'input[type="range"]'
    ) as HTMLDivElement;
    if (input) {
      input.style.backgroundSize = ((value - 0) * 100) / 1000 + "% 100%";
    }
  };

  return (
    <div id="itemThree" className={classNames({ disabled: disabled })}>
      <div className="pad-b20 listSelectedValue">
        <h2 className="main-color">
          {garant
            ? "Quel est le montant des revenus du garant ?"
            : "Quel est le montant de ses revenus"}
        </h2>
        <div className="valueListWrap">
          {valueList?.map((x: TypeList, index: number) => {
            if (index === 0) {
              return (
                <div className="sliderQuantity" key={index}>
                  <h6 data-required="true">Montant du salaire net mensuel</h6>

                  <input
                    className="input"
                    type="number"
                    value={Number(x?.value)}
                    min={0}
                    onChange={(e) =>
                      handleSetValue(Number(e?.target?.value), index)
                    }
                    placeholder="ex: 200"
                    onKeyDown={(e) =>
                      (e.keyCode === 69 ||
                        e.keyCode === 190 ||
                        e.keyCode === 189) &&
                      e.preventDefault()
                    }
                  ></input>
                </div>
              );
            } else {
              return (
                <div className="valueItem" key={index}>
                  <div>
                    <p>{x?.displayName}</p>

                    <img
                      src={remove}
                      alt="loading"
                      onClick={() => {
                        setValueList(
                          valueList?.filter(
                            (y: TypeList, indexFilter: number) =>
                              indexFilter !== index
                          )
                        );
                      }}
                    />
                  </div>

                  <input
                    className="input"
                    type="number"
                    value={Number(x?.value)}
                    min={0}
                    onChange={(e) =>
                      handleChangeNumber(Number(e?.target?.value), index)
                    }
                    placeholder="ex: 200"
                    onKeyDown={(e) =>
                      (e.keyCode === 69 ||
                        e.keyCode === 190 ||
                        e.keyCode === 189) &&
                      e.preventDefault()
                    }
                  ></input>
                </div>
              );
            }
          })}
        </div>
        <div
          className="addValueButton"
          onClick={() => {
            setShow(true);
          }}
        >
          <p>Ajouter des ressource complémentaires</p>
          <img src={plus} alt="plus" />
        </div>
        <div className="buttonGroup">
          <button onClick={handleSubmit} className="submitButton">
            Valider la situation professionnel
          </button>
          <div className="footerButton">
            <img src={clock} alt="loading"></img>
            <p>Sauvegarder mon dossier et finir plus tard</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemThree;
