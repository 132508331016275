import { Checkbox, Form, Upload } from "antd";
import { useAppDispatch, useAppSelector } from "app/hooks";
import paperplane from "assets/image/common/paper-plane.svg"
import { createAssure, createAssuresCopro, createNewCopro, createOwner, createPersonneMorale, createPersonnePhysique, createRepresentant, createSyndicat, updateMRISimulation } from "features/assure/assureAPI";
import { createNewCoproAction, selectAssuresCopro, selectNewCopro } from "features/assure/assureSlice";
import { CreateAssureCoproDto } from "features/assure/assureTypes";
import { handleNext, selectDossierChoices, selectDossierType } from "features/dossierSimuSlice";
import { getCivilitesList } from "features/locataires/locatairesSlice";
import { setSimulationRisqueAssure } from "features/monBien/monBien";
import { parsingMRI } from "helpers/parseObjetToDto";
import { parsePersonneMorale } from "helpers/parsePersonneMorale";
import { createDevisAction } from "pages/mainStep/mainSlice";
import { CONFIRMATION3, CONFIRMATIONSTEP_PAGE3_NAME } from "pages/mainStep/routeList";
import { Civilites } from "pages/mainStep/type";
import { useEffect, useState } from "react";
import Autocomplete from "react-google-autocomplete";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { useNavigate } from "react-router-dom";

interface FormType {
    nom: string;
    prenom: string;
    email: string;
    telephone: string;
    anotherAddress: string;
    address: string;
    ville: string;
    consentement: boolean;
    designation: string;
    siret: string;
    rcsNumero: string;
    rcsVille: string;
    capital: string;
    nature: string;
  }

type InformationProps = {
  type: string;
}

const Information: React.FC<InformationProps> = ({type}) => {

    const [gender, setGender] = useState<Civilites>();
    const [form] = Form.useForm();
    const [dataAddress, setDataAddress] = useState({});
    const [formeJuridique, setFormeJuridique] = useState<number>(1);
    const [isConsentement, setIsConsentement] = useState<boolean>(false);
    const { civilites } = useAppSelector(
        (state: any) => state?.locatairesSlice
      );
      const { courtierId, simulationId, bienId } = useAppSelector(
        (state) => state.mainSlice
      ); 
    const dispatch = useAppDispatch();
    const dossierType = useAppSelector(selectDossierType);
    const mri = useAppSelector(selectDossierChoices);
    const coproprieteId = useAppSelector(selectNewCopro);
    const assureCoproprieteId = useAppSelector(selectAssuresCopro);
    const navigate = useNavigate();

    const handleFormeJuridiqueChange = (value: number) => {
      setFormeJuridique(value);
      form.setFieldsValue({ nature: value });
    };

    const handleConsentement = () => {
      setIsConsentement((prev) => {
        form.setFieldsValue({ consentement: !prev });
        return !prev
      })
    };

    useEffect(() => {
      if (dossierType == "mri") {
        const updateMRISimu = async () => {
          const dataSubmit = parsingMRI(mri, simulationId, coproprieteId, assureCoproprieteId, mri?.ActivitePrincipaleId);
          await updateMRISimulation(dataSubmit);
        }
        updateMRISimu();
      }
    }, [coproprieteId])

    const handleCreationPersonnePhysique = async (formData: FormType) => {
      const data = {
        civiliteId: gender?.id,
        nom: formData.nom,
        prenom: formData.prenom,
        email: formData.email,
        telephone: formData.telephone,
        dateNaissance: null,
        adresse: { ...dataAddress, ligne2: formData?.anotherAddress },
      };
      await createPersonnePhysique(data)
      .then(async (resPP: any) => {
        const createAssureData = {
          identifiantPartenaire: `${resPP?.nom} ${resPP?.prenom}`,
          courtierId: courtierId,
          typePartenaire: 2,
          personneMoraleId: null,
          personnePhysiqueId: resPP?.id,
          natureId: null,
          abpUserId: null,
        };
        if (type != "MRI") {
          createAssure(createAssureData).then(async (res: any) => {
            if (bienId) {
              const dataSubmit = {
                bienImmobilierId: bienId,
                personnePhysiqueId: resPP.id
              }
              await createOwner(dataSubmit).then(() => {
                setSimulationRisqueAssure({simulationId: simulationId, risqueAssureId: bienId, type: type}).then(() => {
                  dispatch(createDevisAction({simulationId: simulationId, assureId: res?.id, contratParentId: undefined}));
                  if (type === "GLI")
                  {
                    navigate(CONFIRMATION3);
                  }
                  else
                    dispatch(handleNext());
                })
              })
            }
          });
        }
        else {
          let syndicatId = "";
          if (formeJuridique === 3) {
            const dataSyndicat = {
              nom: formData?.designation,
              personnePhysiqueId: resPP?.id,
              isPro: false
            }
            await createSyndicat(dataSyndicat).then((data: any) => {
              syndicatId = data.id;
            })
          }

          const dataRepresentant = {
            courtierId: courtierId,
            personnePhysiqueId: resPP?.id,
            syndicatId: formeJuridique > 2 ? syndicatId : null,
        }
          await createRepresentant(dataRepresentant).then(async (data) => {
            const assureCoproprieteData = {
                representantId: data?.id,
                personnesMorales: [
                ],
                personnesPhysiques: [
                ],
                courtierId: courtierId
            };

            await createAssuresCopro(assureCoproprieteData as CreateAssureCoproDto).then(async (assureCopro) => {
              const savedData = parsingMRI(mri, simulationId, coproprieteId, assureCopro.id, mri?.ActivitePrincipaleId);
                await updateMRISimulation(savedData).then((data) => {
                dispatch(createDevisAction({simulationId: simulationId, assureId: assureCopro.id, contratParentId: undefined}));
                dispatch(handleNext());
              });
            })
          })
        }
      })
      .catch((data) => {
          form.setFields([
            {
              name: 'email',
              errors: ["Ce mail est déjà existant. Veuillez appeler votre courtier ou utiliser votre espace assuré."],
            },
          ]);
          console.log('error');
      });
    }

    const handleCreationPersonneMorale = async (formData: FormType) => {
      const data = parsePersonneMorale(formData, gender?.id, dataAddress);
      await createPersonneMorale(data)
      .then(async (resPM: any) => {
        const createAssureData = {
          identifiantPartenaire: `${resPM?.designation}`,
          courtierId: courtierId,
          typePartenaire: 1,
          personneMoraleId: resPM?.id,
          personnePhysiqueId: null,
          natureId: null,
          abpUserId: null,
        };
        if (type != "MRI") {
          createAssure(createAssureData).then(async (res: any) => {
            if (bienId) {
              const dataSubmit = {
                bienImmobilierId: bienId,
                personneMoraleId: resPM.id
              }
              await createOwner(dataSubmit).then(() => {
                setSimulationRisqueAssure({simulationId: simulationId, risqueAssureId: bienId, type: type}).then(() => {
                  dispatch(createDevisAction({simulationId: simulationId, assureId: res?.id, contratParentId: undefined}));
                  if (type === "GLI")
                  {
                    navigate(CONFIRMATION3);
                  }
                  else
                    dispatch(handleNext());
                })
              })
            }
          });
        }
        else {
          let syndicatId = "";
          if (formeJuridique === 4) {
            const dataSyndicat = {
              nom: formData?.designation,
              personneMoraleId: resPM?.id,
              isPro: true
            }
            await createSyndicat(dataSyndicat).then((data: any) => {
              syndicatId = data.id;
            })
          }

          const dataRepresentant = {
            courtierId: courtierId,
            personneMoraleId: resPM?.id,
            syndicatId: syndicatId === "" ? undefined : syndicatId,
        }
          await createRepresentant(dataRepresentant).then(async (data) => {
            const assureCoproprieteData = {
                representantId: data?.id,
                personnesMorales: [
                ],
                personnesPhysiques: [
                ],
                courtierId: courtierId
            };

            await createAssuresCopro(assureCoproprieteData as CreateAssureCoproDto).then(async (assureCopro) => {
              const savedData = parsingMRI(mri, simulationId, coproprieteId, assureCopro.id, mri?.ActivitePrincipaleId);
                await updateMRISimulation(savedData).then((data) => {
                dispatch(createDevisAction({simulationId: simulationId, assureId: assureCopro.id, contratParentId: undefined}));
                dispatch(handleNext());
              });
            })
          })
        }
      })
      .catch((data) => {
          form.setFields([
            {
              name: 'email',
              errors: ["Ce mail est déjà existant. Veuillez appeler votre courtier ou utiliser votre espace assuré."],
            },
          ]);
          console.log('error');
      });
    }

    const handleSubmit = async (formData: FormType) => {
       if (formeJuridique === 1 || formeJuridique === 3) {
        await handleCreationPersonnePhysique(formData);
       } else {
        await handleCreationPersonneMorale(formData);
       }
    }

    const handleFormatPlace = (place: any) => {
        const listPlace = place?.address_components;
        const googlePlaceId = place?.place_id;
        const codePostal = listPlace?.find((x: any) =>
          x?.types?.includes("postal_code")
        )?.long_name;
        const country = listPlace?.find((x: any) =>
          x?.types?.includes("country")
        )?.long_name;
        const city = listPlace?.find((x: any) =>
          x?.types?.includes("locality")
        )?.long_name;
        const address = place?.formatted_address?.split(",")?.[0];
        geocodeByAddress(place?.formatted_address)
          .then((results) => getLatLng(results[0]))
          .then(({ lat, lng }) => {
            const dataAddress = {
              latitude: `${lat}`,
              longitude: `${lng}`,
              pays: country,
              ville: city,
              codePostal,
              adresseFormatee: address
                ? `${address},${city},${country}`
                : place?.formatted_address,
              googlePlaceId,
              ligne1: address,
            };
            setDataAddress(dataAddress);
    
            form.setFieldsValue({
              address: address,
              ville: `${city} (${codePostal})`,
            });
          });
      };

      useEffect(() => {
        dispatch(getCivilitesList());
        form.setFieldsValue({ nature: 1 });
      }, [])

    return (
        <div id={`monBienForm`}>
            <div className="d-flex flex-direction-col flex-hor-center flex-ver-center">
                <img src={paperplane} alt="loading" className="houseImage mg-t20"></img>
                <h2 className="mg-b30 mg-t20">Quelques informations sur vous avant de commencer</h2>
            </div>
            <div className="container">
                <Form onFinish={handleSubmit} form={form} autoComplete="off"
                      initialValues={{ consentement: false }}
                >
                  <div className="formItem">
                        <p data-required="true">Nature du client</p>
                        <Form.Item
                          name="nature"
                          rules={[{ required: true, message: 'Veuillez choisir une forme juridique' }]}
                        >
                         <ul className="w-100 d-flex  radioGroup flex-wrap flex-hor-between">
                            <li
                              className={`w-list mg-r10 mg-b10 radius-4 cursor  ${
                                formeJuridique === 1 ? "activeLi" : ""
                              }`}
                              onClick={() => handleFormeJuridiqueChange(1)}
                            >
                              <span className="font-w600 font-14 lh-17">
                                Nom propre
                              </span>
                            </li>
                             <li
                              className={`w-list mg-r10 mg-b10 radius-4 cursor  ${
                                formeJuridique === 2 ? "activeLi" : ""
                              }`}
                              onClick={() => handleFormeJuridiqueChange(2)}
                            >
                              <span className="font-w600 font-14 lh-17">
                                Société
                              </span>
                            </li>
                            {
                            type === "MRI" &&
                            <>
                             <li
                              className={`w-list mg-r10 mg-b10 radius-4 cursor  ${
                                formeJuridique === 3 ? "activeLi" : ""
                              }`}
                              onClick={() => handleFormeJuridiqueChange(3)}
                            >
                              <span className="font-w600 font-14 lh-17">
                                Syndic. bénévole
                              </span>
                            </li>
                             <li
                              className={`w-list mg-r10 mg-b10 radius-4 cursor ${
                                formeJuridique === 4 ? "activeLi" : ""
                              }`}
                              onClick={() => handleFormeJuridiqueChange(4)}
                            >
                              <span className="font-w600 font-14 lh-17">
                                Syndic. professionnel
                              </span>
                            </li>
                            </>
                            }
                        </ul>
                        </Form.Item>
                    </div>
                    {
                      formeJuridique >= 2 &&
                      <>
                        <div className="formItem">
                          <p className="label">{formeJuridique === 2 ? "Nom de votre société" : "Nom du syndicat"}</p>
                          <Form.Item name="designation" 
                            rules={[{ required: true, message: "Veuillez rentrer une désignation"}]}
                          >
                              <input
                                  placeholder="Désignation"
                                  className={`input ${type == "GLI" ? "custom-focus" : ""}`}
                                  name="designation"
                              ></input>
                          </Form.Item>
                        </div>
                        {
                          (formeJuridique >= 2 && formeJuridique != 3) &&
                          <>
                            <div className="formItem">
                              <p className="label">Siret</p>
                              <Form.Item name="siret" 
                              rules={[{ required: true, message: "Veuillez rentrer un numéro de SIRET valide", pattern: /^\d{3}\s?\d{3}\s?\d{3}\s?\d{5}$/}]}
                              >
                                  <input
                                      placeholder="Désignation"
                                      className={`input ${type == "GLI" ? "custom-focus" : ""}`}
                                      name="siret"
                                  ></input>
                              </Form.Item>
                            </div>
                            <div className="formItem">
                              <p>RCS Numéro</p>
                              <Form.Item name="rcsNumero" 
                              >
                                  <input
                                      placeholder="RCS Numéro"
                                      className={`input ${type == "GLI" ? "custom-focus" : ""}`}
                                      name="rcsNumero"
                                  ></input>
                              </Form.Item>
                            </div>
                            <div className="formItem">
                              <p>RCS Ville</p>
                              <Form.Item name="rcsVille" 
                              >
                                  <input
                                      placeholder="RCS Ville"
                                      className={`input ${type == "GLI" ? "custom-focus" : ""}`}
                                      name="rcsVille"
                                  ></input>
                              </Form.Item>
                            </div>
                            <div className="formItem">
                              <p>Capital social</p>
                              <Form.Item name="capital" 
                              >
                                  <input
                                      placeholder="Capital Social"
                                      className={`input ${type == "GLI" ? "custom-focus" : ""}`}
                                      name="capital"
                                  ></input>
                              </Form.Item>
                            </div>
                            <div className="formItem">
                              <p className="label">Forme juridique</p>
                              <Form.Item name="formeJuridique" 
                              rules={[{ required: true, message: "Veuillez remplir ce champ"}]}
                              >
                                  <input
                                      placeholder="Ex: SA"
                                      className={`input ${type == "GLI" ? "custom-focus" : ""}`}
                                      name="formeJuridique"
                                  ></input>
                              </Form.Item>
                            </div>
                          </>
                        }

                      </>
                    }
                <div className="d-flex w-100 flex-ver-center">
                  <p className="mg-t10 mg-b10 font-w600 font-16 text-left">Informations du représentant</p>
                </div>
                <div className="mg-b20 formItem">
                  <p data-required="true">Civilité</p>
                  <ul className="w-100 d-flex  radioGroup">
                    
                    {civilites?.map((x: Civilites, index: number) => (
                      <li
                        key={index}
                        className={`w-50 radius-4 cursor mg-r10 ${
                          gender?.id === x?.id ? "activeLi" : ""
                        }`}
                        onClick={() => setGender(x)}
                      >
                        <span className="font-w600 font-14 lh-17">
                          {x?.libelle}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
                    <div className="formItem">
                        <p className="label">Nom</p>
                        <Form.Item name="nom" 
                        rules={[{ required: true, message: "Veuillez rentrer votre nom" }]}
                        >
                            <input
                                placeholder="Ex : Dupont"
                                className={`input ${type == "GLI" ? "custom-focus" : ""}`}
                                name="nom"
                            ></input>
                        </Form.Item>
                    </div>
                    <div className="formItem">
                        <p className="label">Prénom</p>
                        <Form.Item name="prenom" 
                        rules={[{ required: true, message: "Veuillez rentrer votre prenom" }]}
                        >
                            <input
                                placeholder="Ex : Pierre"
                                className={`input ${type == "GLI" ? "custom-focus" : ""}`}
                                name="prenom"
                            ></input>
                        </Form.Item>
                    </div>
                    <div className="formItem">
                        <p className="label">Adresse</p>
                        <Form.Item
                        name="address"
                        rules={[{ required: true, message: "Please input your city!" }]}
                        >
                        <Autocomplete
                            className={`input ${type == "GLI" ? "custom-focus" : ""}`}
                            id={"countryAnother" + Math.floor(Math.random() * 10000)}
                            placeholder="Adresse"
                            apiKey="AIzaSyCCelMiuDG-cQfWRb0KWDyPD_glL2hrwPM"
                            onPlaceSelected={(place) => {
                            handleFormatPlace(place);
                            }}
                            options={{
                            types: ["geocode", "establishment"],
                            componentRestrictions: { country: "fr" },
                            }}
                        />
                        </Form.Item>
                    </div>
                    <div className="formItem">
                        <p className="label">Ville</p>
                        <Form.Item
                        name="ville"
                        rules={[{ required: true, message: "Please input your country!" }]}
                        >
                        <input placeholder="ville" 
                        className={`input ${type == "GLI" ? "custom-focus" : ""}`}
                        ></input>
                        </Form.Item>
                    </div>

                    <div className="formItem">
                        <p>Complément d'adresse</p>
                        <Form.Item name="anotherAddress">
                        <input placeholder="Complément d'adresse" 
                        className={`input ${type == "GLI" ? "custom-focus" : ""}`}
                        ></input>
                        </Form.Item>
                    </div>
                    <div className="formItem">
                        <p className="label">Email</p>
                        <Form.Item name="email" 
                        rules={[{ required: true, message: "Veuillez rentrer un mail valide", type: "email" }]}
                        >
                            <input
                                placeholder="Ex : exemple@exemple.fr"
                                className={`input ${type == "GLI" ? "custom-focus" : ""}`}
                                name="email"
                            ></input>
                        </Form.Item>
                    </div>
                    <div className="formItem">
                        <p className="label">Numéro de téléphone</p>
                        <Form.Item name="telephone" 
                        rules={[{ required: true, message: "Veuillez rentrer un numero de téléphone valide" , pattern: new RegExp(/^(\+33\s?|0)[1-9](\s?\d{2}){4}$/)}]}
                        >
                            <input
                                placeholder="Ex : 0634097845"
                                className={`input ${type == "GLI" ? "custom-focus" : ""}`}
                                name="telephone"
                            ></input>
                        </Form.Item>
                    </div>
                    
                    <div>

                    </div>
                    <div className="d-flex flex-direction-row container-checkbox" style={{alignItems: "center"}}>
                        <input type="checkbox" className="custom-check" checked={isConsentement} onChange={() => handleConsentement()}/>
                        <label className="text-left mg-l20 w-100">J'accepte de recevoir des informations personnalisées de la part de Confiancia (garantie zéro spam)</label>
                    </div>
                    <button className="submitButton" type="submit">
                        Valider mes informations
                    </button>
                </Form>
            </div>
        </div>
    )
}

export default Information