import { useAppDispatch, useAppSelector } from "app/hooks";
import Loading from "components/Loading/loading";
import { getGarantieByTunnel } from "features/components/choiceGarantie/garantie";
import { handleNext, setShowHeader } from "features/dossierSimuSlice";
import {getSimulationInfo } from "pages/mainStep/mainStepAPI";
import { IGarantie } from "pages/mainStep/type";
import {  useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { selectAssureListEmp } from "../emprunteurSlice";
import ChoiceGarantieEmp from "./choiceGarantieEmp";


const TarificateurEmp: React.FC = () => {
  
  const [garanties, setGaranties] = useState<IGarantie[]>([]);
  const [garantiesRetenues, setGarantiesRetenues] = useState<string[]>([]);
  const [tunnelId, setTunnelId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const listAssure = useAppSelector(selectAssureListEmp);
    const dispatch = useAppDispatch();
    const { simulationId } = useAppSelector(
        (state: any) => state.mainSlice
      );
    const location= useLocation();

    //Fetch des datas au load de la page
    useEffect(() => {
      fetchGarantiesDisponibles();
    }, [])

      const fetchGarantiesDisponibles = async () => {
          setLoading(true);
          const res = await getSimulationInfo(simulationId, location.pathname.split("/")[1].toUpperCase());
          await getGarantieByTunnel(res.tunnelId).then(async (garanties) => {
            setTunnelId(res.tunnelId);
            setGaranties(garanties);
            setGarantiesRetenues(garanties.filter((garantie: any) => garantie?.isRequired === true).map((garantie: any) => garantie?.id))
            setLoading(false);
          }).catch();
      } 

    const isButtonDisabled = () => {
      const isError = listAssure.some(assure => checkErrorGarantieByAssure(assure));
      return isError;
    }
  
    const checkErrorGarantieByAssure = (currentAssure: any) => {
      const count = currentAssure?.prets?.filter((pret: any) => pret?.garanties?.length === 0)?.length;
      if (count > 0) {
        return true;
      }
      return false;
    }

    const submitGaranties = async () => {
      if (isButtonDisabled())
        return ;
      dispatch(handleNext());
    }

    return (
        <>
        <div className={`container`}>
            <ChoiceGarantieEmp 
            garanties={garanties}
            garantiesRetenues={garantiesRetenues}
            submit={submitGaranties}
            />
          </div>
          <Loading loading={loading} isFullPage/>
        </>
    )
}

export default TarificateurEmp;