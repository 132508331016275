import { useAppDispatch, useAppSelector } from "app/hooks";
import {  selectDossierPrets, setShowHeader } from "features/dossierSimuSlice";
import React, { ReactNode, useEffect, useRef, useState } from "react"
import { IGarantie } from "pages/mainStep/type";
import Tabs from "features/components/tabs/tabs";
import {ReactComponent as Checked} from "assets/image/mri/check-blue.svg"
import { selectAssureListEmp, setGarantieByAssureAndPret, setGarantieObligatoireOnAllAssure } from "../emprunteurSlice";


type Props = {
    garanties: IGarantie[];
    garantiesRetenues: string[];
    submit: () => void;
}

const ChoiceGarantieEmp: React.FC<Props> = ({garanties, garantiesRetenues, submit}) => {

    const dispatch = useAppDispatch();
    const myDivRef = useRef<HTMLDivElement>(null);
    const [active, setActive] = useState<number>(1);
    const pret = useAppSelector(selectDossierPrets);
    const assureList = useAppSelector(selectAssureListEmp);

    useEffect(() => {
      if (!assureList[0]?.prets[0]?.garanties || assureList[0]?.prets[0]?.garanties?.length === 0) {
      const garantieRequired = garanties?.filter((currentGarantie: any) => currentGarantie?.isRequired === true);
      dispatch(setGarantieObligatoireOnAllAssure({garanties: garantieRequired}));
      }
    }, [garanties])

    const tabs = assureList?.map((currentAssure: any, index: number) => {
      return {
        label: currentAssure?.assureInfo?.information?.prenom + " " + currentAssure?.assureInfo?.information?.nom,
        value: index + 1,
        haveNumbers: false
      }
    })

  
    const checkErrorGarantieByAssure = () => {
      const count = assureList[active - 1]?.prets?.filter((pret: any) => pret?.garanties?.length === 0)?.length;
      if (count > 0) {
        return true;
      }
      return false;
    }

    const handleNextTab = () => {
      if (checkErrorGarantieByAssure() === true) {
        return ;
      } else if (active != assureList?.length) {
            setActive((prev)=> prev+1);
        } else {
          submit();
        }
    }
    
    const isRetenue = (id:string, indexAssure: number, indexPret: number) => {
        const garantie: any = assureList[indexAssure]?.prets[indexPret]?.garanties?.find((currentGarantie: any) => currentGarantie?.id === id);
        return (garantie ? true : false);
    }

    const handleGarantie = (indexPret: number, indexAssure: number, garantie: IGarantie) => {

      if (garantie?.isRequired)
      {
        return ;
      }

      const isGarantieRetenue = isRetenue(garantie?.id, indexAssure, indexPret);

      if (indexAssure < assureList.length && assureList[indexAssure] && indexPret < assureList[indexAssure].prets.length) {
        let newGaranties = null;
    
        const pret = assureList[indexAssure].prets[indexPret];
        const currentGaranties = pret?.garanties || [];
    
        if (isGarantieRetenue) {
          newGaranties = currentGaranties.filter((currentGarantie: any) => currentGarantie?.id !== garantie?.id && currentGarantie?.garantieParent != garantie?.id);
        } else {
          newGaranties = [...currentGaranties, garantie];
        }
    
        dispatch(setGarantieByAssureAndPret({ assureIndex: indexAssure, pretIndex: indexPret, garanties: newGaranties }));
      }
    }

    const handleGarantieEnfant = (indexPret: number, indexAssure: number, garantie: IGarantie, garantieParent: IGarantie) => {
      const isGarantieRetenue = isRetenue(garantie?.id, indexAssure, indexPret);
      const isGarantieParentRetenue = isRetenue(garantieParent?.id, indexAssure, indexPret);

      if (indexAssure < assureList.length && assureList[indexAssure] && indexPret < assureList[indexAssure].prets.length) {
        let newGaranties = null;
    
        const pret = assureList[indexAssure].prets[indexPret];
        const currentGaranties = pret?.garanties || [];
    
        if (isGarantieRetenue) {
          newGaranties = currentGaranties.filter((currentGarantie: any) => currentGarantie?.id !== garantie?.id);
        } else {
          if (!isGarantieParentRetenue)
            newGaranties = [...currentGaranties, {...garantie, garantieParent: garantieParent?.id}, garantieParent];
          else
            newGaranties = [...currentGaranties, {...garantie, garantieParent: garantieParent?.id}];
        }
    
        dispatch(setGarantieByAssureAndPret({ assureIndex: indexAssure, pretIndex: indexPret, garanties: newGaranties }));
      }
    }


    return (
        <div className="d-flex flex-direction-col choice-garantie flex-ver-center">
          <h2 className="mg-b30 mg-t40 mg-l10 mg-r10 font-w700 font-22 mg-t20">Choix des garanties</h2>
          <div className="mg-b30 w-90">
            <Tabs 
              tabs={tabs}
                active={active}
                setActive={setActive}
                customStyle="w-100-imp"
            />
          </div>

          <div className="d-flex flex-ver-center flex-hor-center flex-direction-col">
          {
            pret?.map((currentPret: any, indexPret: number) => {
            return (
              <React.Fragment  key={"pret" + indexPret}>
                <div className="pret-garantie-box mg-b20">
                  <p className="title-pret mg-b20">Prêt n°{indexPret + 1} - {currentPret?.montantPret} €</p>
                {
                  garanties?.map((currentGarantie: any, index: number) => {
                    return (
                      <>
                        <div className={`garantie-box ${isRetenue(currentGarantie?.id, active - 1, indexPret) ? "active" : ""}`}  
                          key={"garantiePret" + index + indexPret}
                          onClick={() => handleGarantie(indexPret, active - 1, currentGarantie)}
                        >
                          <p className="w-90">
                            {currentGarantie?.descriptionCourte}
                          </p>
                          {
                            isRetenue(currentGarantie?.id, active -1, indexPret) ?
                            <Checked />
                            : null
                          }
                        </div>
                        {currentGarantie?.enfants?.length > 0 &&
                        <div className="garantie-enfant-container">
                        {
                          currentGarantie?.enfants?.map((garantieEnfant: any) => {
                            return (
                              <div className={`garantie-enfant-box ${isRetenue(garantieEnfant?.id, active -1, indexPret) ? "active" : ""}`}  
                                key={"garantiePret" + index + indexPret}
                                onClick={() => handleGarantieEnfant(indexPret, active - 1, garantieEnfant, currentGarantie)}
                              >
                                <p className="w-90">
                                  {garantieEnfant?.descriptionCourte}
                                </p>
                                {
                                  isRetenue(garantieEnfant?.id, active -1, indexPret) ?
                                  <Checked />
                                  : null
                                }
                              </div>
                            )
                          })
                        }
                        </div>
                      }
                      </>
                    )
                  })
                }
                </div>
              </React.Fragment>
            )
            }) 
          }
          </div>
        
          <div className="w-100 d-flex flex-direction-col flex-ver-center contratStepOneFooter mg-t40" ref={myDivRef}>
            <button
              disabled={garantiesRetenues.length < 0}
              className="submitButton cttfr2 w-90"
              onClick={() => handleNextTab()}
              style={{marginTop: "30px"}}
            >
              Je valide mes garanties
            </button>
            <p className="font-13 lh-19 mg-b5 cttfr3">
              Des questions ? Appelez-nous.
            </p>
            <div className="d-flex flex-ver-center mg-b7 cttfr4">
              <p className="mg-r8">0900 752 857</p>
              <h6>du lundi au vendredi de 9h à 17h30</h6>
            </div>
            <p className="font-9 lh-19 cttfr5">
              *prix d’un appel local + frais éventuels de votre opérateur
            </p>
          </div>
        </div>
    )
}

export default ChoiceGarantieEmp