import { FormInstance, Select } from "antd"
import FormItem from "antd/es/form/FormItem";
import AmortissableForm from "./amortissableForm";

type Props = {
    form: FormInstance;
}

const CreditBail: React.FC<Props> = ({form}) => {
    return (
        <>
            <AmortissableForm form={form}/>
            <div className="formItem">
                <p data-required="true">Durée restante (en mois)</p>
                <FormItem name="dureeRestante" 
                    rules={[
                        { required: true, message: "Veuillez rentrer la duree restante" },
                        { pattern: /^\d+$/, message: "Veuillez entrer uniquement des chiffres" }
                        ]}
                >
                    <input
                        type="number"
                        className={`input`}
                        name="dureeRestante"
                        placeholder="Duree restante"
                    ></input>
                </FormItem>
            </div>
            <div className="formItem">
                <p data-required="true">Montant du loyer</p>
                <FormItem name="montantLoyer" 
                    rules={[
                        { required: true, message: "Veuillez rentrer la duree du montant du loyer" },
                        { pattern: /^\d+$/, message: "Veuillez entrer uniquement des chiffres" }
                        ]}
                >
                    <input
                        type="number"
                        className={`input`}
                        name="montantLoyer"
                        placeholder="Montant du loyer"
                    ></input>
                </FormItem>
            </div>
            <div className="formItem">
                <p data-required="true">Valeur résiduelle</p>
                <FormItem name="valeurResiduelle" 
                    rules={[
                        { required: true, message: "Veuillez rentrer la valeur residuelle"},
                        { pattern: /^\d+$/, message: "Veuillez entrer uniquement des chiffres" }
                        ]}
                >
                    <input
                        type="number"
                        className={`input`}
                        name="valeurResiduelle"
                        placeholder="Valeur residuelle"
                    ></input>
                </FormItem>
            </div>
        </>
    )
}
export default CreditBail