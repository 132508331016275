import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PageTransition } from "@steveeeie/react-page-transition";

import MonBien from "../monBien";
import Locataires from "../locataires";
import Contrat from "../contrat";
import Pieces from "../pieces";
import Confirmation from "../confirmation";
import Connexion from "pages/connexion";
import classNames from "classnames";
import { setFirst } from "./mainSlice";
import { TypeList } from "./type";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { ReactComponent as PrevArrow } from "assets/image/startStep/prevArrow.svg";
import information from "assets/image/common/information.svg";
import { RouteList } from "./type";
import {
  CONFIGASSU_LABEL,
  CONFIRMATION_LABEL,
  CONNEXION_LABEL,
  LOCATAIRES_LABEL,
  MONBIEN_LABEL,
  UPDOC_LABEL,
} from "./routeList";

const MainStep = ({ style }: any) => {
  const [direction, setDirection] = useState("moveToLeftFromRight");
  const [showHeader, setShowHeader] = useState(true);
  const [key, setKey] = useState("100");
  const [oldKey, setOldKey] = useState(-1);

  const [show, setShow] = useState(false);
  const [selectValue, setSelectValue] = useState<TypeList | null>();
  const [valueList, setValueList] = useState<TypeList[]>([]);
  const [newValueList, setNewValueList] = useState<TypeList[]>([]);

  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { routeList, first } = useAppSelector(
    (state) => state.mainSlice
  );
  const { typesList } = useAppSelector((state) => state?.locatairesSlice); 

  useEffect(() => {
    if (!first) {
      const current = routeList?.find((x: RouteList) =>
        x?.list?.includes(location?.pathname)
      );
      if (current) {
        const target: RouteList | any = routeList?.find((x: RouteList) =>
          x?.list?.includes(location?.pathname)
        );

        const newDirection =
          Number(oldKey) < Number(target?.key)
            ? "moveToLeftFromRight"
            : "moveToRightFromLeft";
        setOldKey(target?.key);
        setDirection(newDirection);
        setTimeout(() => {
          setKey(`${target?.key}`);
        }, 100);
      } else {
        const firstTarget: any = routeList?.[0];
        navigate(firstTarget?.list?.[0]);
      }
    } else {
      const firstTarget: RouteList = routeList?.[0];
      if (firstTarget) {
        navigate(firstTarget?.list?.[0]);
        dispatch(setFirst(false));
      }
    }
  }, [dispatch, location?.pathname, navigate, routeList, first]);

  useEffect(() => {
    setNewValueList(valueList);
  }, [show]);

  return (
    <div id="mainStep">

      {key && (
        <PageTransition
          preset={direction}
          transitionKey={key}
          enterAnimation={""}
          exitAnimation={""}
        >
          {routeList?.map((x: RouteList) => {
            if (x?.key === Number(key)) {
              switch (x?.label) {
                case MONBIEN_LABEL:
                  return (
                    <div className="wrapper" key={x?.key}>
                      <MonBien />
                    </div>
                  );
                case LOCATAIRES_LABEL:
                  return (
                    <div className="wrapper" key={x?.key}>
                      <Locataires
                        show={show}
                        setShow={setShow}
                        setValueList={setValueList}
                        valueList={valueList}
                      />
                    </div>
                  );
                case CONNEXION_LABEL:
                  return (
                    <div className="wrapper" key={x?.key}>
                      <Connexion />
                    </div>
                  );
                case CONFIGASSU_LABEL:
                  return (
                    <div
                      className={classNames("wrapper", {
                        hiddenHeader: !showHeader,
                      })}
                      key={x?.key}
                    >
                      <Contrat setShowHeader={setShowHeader}></Contrat>{" "}
                    </div>
                  );
                case UPDOC_LABEL:
                  return (
                    <div className="wrapper" key={x?.key}>
                      <Pieces setShowHeader={setShowHeader}></Pieces>
                    </div>
                  );
                case CONFIRMATION_LABEL:
                  return (
                    <div className="wrapper" key={x?.key}>
                      <Confirmation></Confirmation>
                    </div>
                  );
              }
            }
          })}
        </PageTransition>
      )}
      <div className={classNames("addNewValueTable", { show })}>
        <div className="addValueArea">
          <div className="w-100 d-flex flex-hor-between flex-ver-center mg-b26 addValueArea-header">
            <PrevArrow
              className="cursor"
              onClick={() => {
                setShow(false);
                setSelectValue(null);
                setNewValueList([]);
              }}
            />
            <h6>Ressources complémentaires</h6>
            <img
              src={information}
              alt="information"
              className="cursor"
              onClick={() => {
                setShow(false);
                setSelectValue(null);
                setNewValueList([]);
              }}
            ></img>
          </div>
          <p className="font-16 lh-25 mg-b27 addValueArea-content">
            Sélectionnez les ressources complémentaires du garant de votre
            locataire s’il y en a
          </p>
          <div className="valueList">
            {typesList?.map((x: TypeList, index: number) => {
              const valueMap = newValueList
                ?.slice(1)
                ?.map((x: TypeList) => x?.id);

              return (
                <div
                  key={index}
                  className={classNames({
                    active:
                      selectValue?.id === x?.id || valueMap?.includes(x?.id),
                  })}
                  onClick={() => {
                    if (!valueMap?.includes(x?.id)) {
                      if (selectValue?.id === x?.id) {
                        setSelectValue(null);
                      } else {
                        setSelectValue(x);
                      }
                    } else {
                      const theNewValueList = newValueList
                        ?.slice(1)
                        ?.filter((y: TypeList) => y?.id !== x?.id);
                      setNewValueList([valueList?.[0], ...theNewValueList]);
                    }
                  }}
                >
                  <p>{x?.displayName}</p>
                </div>
              );
            })}
            </div>
          <button
            className="submitButton"
            // disabled={!selectValue?.id && newValueList?.length === 0}
            onClick={() => {
              if (selectValue?.id) {
                setValueList([...newValueList, { ...selectValue }]);
                setShow(false);
                setSelectValue(null);
              } else {
                setValueList(newValueList);
                setShow(false);
                setSelectValue(null);
              }
            }}
          >
            Ajouter les ressources
          </button>
        </div>
      </div>
    </div>
  );
};
export default MainStep;
