import { FormInstance, Select } from "antd"
import FormItem from "antd/es/form/FormItem";

type Props = {
    form: FormInstance;
}

const TauxZero: React.FC<Props> = ({form}) => {
    return (
        <>
            <div className="formItem">
                <p data-required="true">Durée totale du prêt avec differé</p>
                <FormItem name="dureeTotale" 
                    rules={[
                        { required: true, message: "Veuillez rentrer la duree totale du pret" },
                        { pattern: /^\d+$/, message: "Veuillez entrer uniquement des chiffres" }
                        ]}
                >
                    <input
                        type="number"
                        className={`input`}
                        name="dureeTotale"
                        placeholder="Duree totale du pret"
                    ></input>
                </FormItem>
            </div>
            <div className="formItem">
                <p data-required="true">Differé (en mois)</p>
                <FormItem name="differe" 
                    rules={[
                        { required: true, message: "Veuillez rentrer la duree du differé" },
                        { pattern: /^\d+$/, message: "Veuillez entrer uniquement des chiffres" }
                        ]}
                >
                    <input
                        type="number"
                        className={`input`}
                        name="differe"
                        placeholder="Duree du differé"
                    ></input>
                </FormItem>
            </div>
        </>
    )
}
export default TauxZero