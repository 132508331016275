import { Button, Card, FormInstance, Select } from "antd"
import FormItem from "antd/es/form/FormItem";
import FormList from "antd/es/form/FormList";
import { useIsSmallScreen } from "hooks/isSmallScreen";
import trash from "assets/image/infoStepFour/remove.svg"
import {ReactComponent as Plus} from "assets/image/emprunteur/plus-blue.svg"

type Props = {
    form: FormInstance;
}

const PretPalier: React.FC<Props> = ({form}) => {

    const isSmallScreen = useIsSmallScreen(567)

    return (
        <>
            <div className="formItem">
                <p data-required="true">Durée totale du prêt avec differé</p>
                <FormItem name="dureeTotale" 
                    rules={[
                        { required: true, message: "Veuillez rentrer la duree totale du pret" },
                        { pattern: /^\d+$/, message: "Veuillez entrer uniquement des chiffres" }
                        ]}
                >
                    <input
                        type="number"
                        className={`input`}
                        name="dureeTotale"
                        placeholder="Duree totale du pret"
                    ></input>
                </FormItem>
            </div>
            <FormList name="palier">
            {(fields, { add, remove }) => (
                <div
                    style={{
                    display: 'flex',
                    rowGap: 16,
                    flexDirection: 'column',
                    }}
                >
                    {fields.map((field, index) => (
                    
                        <Card
                            size="small"
                            title={`Palier ${field.name + 1}`}
                            key={field.key}
                            className="palier-card"
                            extra={
                                <img src={trash} alt="trash" onClick={() => remove(index)} style={{height: "25px", width: "25px"}}/>
                            }
                        >
                            <div className={`d-flex ${isSmallScreen ? "flex-direction-col" : ""}`}>
                                <div className={`formItem  mg-r20 ${isSmallScreen ? "w-100" : "w-45"}`}>
                                    <p data-required="true">Duréé</p>
                                    <FormItem name={[field.name, 'duree']}
                                      rules={[
                                        { required: true, message: "Veuillez rentrer la duree du palier" },
                                        { pattern: /^\d+$/, message: "Veuillez entrer uniquement des chiffres" }
                                        ]}
                                    >
                                        <input
                                            type="duree"
                                            className={`input`}
                                            name="duree"
                                            placeholder="Duree du palier"
                                            style={{width: "90%"}}
                                        ></input>
                                    </FormItem>
                                </div>
                                <div className={`formItem  mg-r20 ${isSmallScreen ? "w-100" : "w-45"}`}>
                                    <p data-required="true">Montant</p>
                                    <FormItem name={[field.name, 'montant']}
                                      rules={[
                                        { required: true, message: "Veuillez rentrer le montant du palier" },
                                        { pattern: /^\d+$/, message: "Veuillez entrer uniquement des chiffres" }
                                        ]}
                                    >
                                        <input
                                            type="montant"
                                            className={`input`}
                                            name="montant"
                                            placeholder="Montant du palier"
                                            style={{width: "90%"}}
                                        ></input>
                                    </FormItem>
                                </div>
                            </div>
                        </Card>
                    ))}
                    <Button  onClick={() => add()} block className="mg-b30 btn-palier">
                        <div className="d-flex flex-hor-center flex-ver-center">
                            <Plus className="mg-r20"/>
                            Ajouter un palier

                        </div>
                    </Button>
               </div>
            )}
            
            </FormList>

        </>
    )
}
export default PretPalier