import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { handleNext, selectDossierChoices } from "features/dossierSimuSlice";
import arrow from "assets/image/startStep/arrow.svg";

type Props = {
  handleOnClick: (
    fieldName: string,
    value: boolean | string | any
  ) => void;
};

const ProchaineAG: React.FC<Props> = ({ handleOnClick }) => {
  const mri = useAppSelector(selectDossierChoices);
  const [dateAg, setDateAg] = useState(mri && mri.dateAg ? mri.dateAg : null);
  const dispatch = useAppDispatch()

  const handleChange = (value: string) => {
    setDateAg(value);
  };

  const handleSubmit = () => {
      handleOnClick("dateAg", dateAg);
      dispatch(handleNext());
    }

  return (
    <div className="max-w1300 mg-0-auto ccc-body-step-2">
      <div className="w-100 text-center border-box radius-15 cccbs2r1">
        <div className="mg-b50">
          <div>
            <p className="font-18 lh-22 text-dark cccbs2r1r1">
              Quelle est la date de prochaine Assemblée Générale de la copropriété ?
            </p>
          </div>
        </div>
        <div
          className="d-flex flex-hor-center cccbs2r1r2 mg-30"
          style={{ margin: 30 }}
        >
          <div className="block" style={{ padding: "16px 8vw" }}>
            <input
              value={dateAg}
              onChange={(e) => handleChange(e.target.value)}
              className="pr-db-input-bg w-100"
              type="date"
            />
          </div>
        </div>
        <div className="cursor d-flex flex-hor-end mg-r20" onClick={() => dispatch(handleNext())}>
            <p className=" font-14 text-dark-light font-w700 mg-r10">
              Passer cette étape
            </p>
            <img src={arrow} alt="next" />
        </div>
      </div>
      {
          <div className="d-flex flex-hor-end mg-t10">
          <button
            className="submitButton w-30"
            onClick={() => handleSubmit()}
            disabled={!dateAg}
          >
            Étape suivante
          </button>
        </div>
      }
    </div>
  );
};

export default ProchaineAG;
