import { Form } from "antd";
import { useForm } from "antd/es/form/Form";
import { fetchNaturePret } from "features/Emprunteur/emprunteurAPI";
import { useEffect, useState } from "react";
import AmortissableForm from "./amortissableForm";
import TauxZero from "./tauxZero";
import { useIsSmallScreen } from "hooks/isSmallScreen";
import PretRelais from "./pretRelais";
import CreditBail from "./creditBail";
import { selectDossierPrets, setPrets } from "features/dossierSimuSlice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import PretPalier from "./pretPalier";

type Props = {
    onCancel: (pram:void) => void;
    currentPret: {
        no: number, 
        pret: any,
        label?: string
    };
    onSubmit?: (values: any) => void,
    noTitle?: boolean
}

const PretForm: React.FC<Props> = ({onCancel, currentPret, onSubmit, noTitle}) => {

    const [naturePret, setNaturePret] = useState<string>(currentPret?.pret?.naturePret);
    const [listNaturePret, setListNaturePret] = useState<any>(); 
    const [form] = Form.useForm();
    const isSmallScreen = useIsSmallScreen(567);
    const prets = useAppSelector(selectDossierPrets);
    const dispatch = useAppDispatch();

    const handleSubmit = (values: any) => {
        let newPrets = [];
        if (currentPret.pret === null) {
            newPrets = [...prets, values];
        }
        else {
            const updatedPrets = [...prets];
            updatedPrets[currentPret.no - 1] = values;
            newPrets = updatedPrets;
        }
        dispatch(setPrets(newPrets));
        if (onSubmit) {
            onSubmit(values);
        }
        onCancel();
    }

    useEffect(() => {
        const getNaturePret = async () => {
            const naturePret = await fetchNaturePret();
            setListNaturePret(naturePret);
        }

        getNaturePret();
    }, [])


    const getFormByNaturePret = (naturePret: string) => {

        //TODO reset les champs du formilaire liees a la forme du pret
        const currentNature = listNaturePret?.find((nature: any) => nature?.id === naturePret);

        switch (currentNature?.libelle) {
            case "Amortissable" :
                return  <AmortissableForm form={form} />
            case "PTZ":
                return  <TauxZero form={form} />
            case "Relais":
                return  <PretRelais form={form} />
            case "Palier":
                return  <PretPalier form={form} />
            case "Crédit bail":
                return  <CreditBail form={form} />
            case "Infiné":
                return  <PretRelais form={form} />
            case "Crédit vendeur":
                return  <AmortissableForm form={form} />
            default:
                return  <></>
            }
        }

    return (
        <div className="d-flex flex-hor-center flex-ver-center flex-direction-col w-100 h-100 overflow-hidden">
            <h2 className="mg-b40 pad-l15">Prêt {currentPret?.no}</h2>
            <div className="mg-t30 w-100 h-100 d-flex flex-hor-center  overflow-auto">
                <Form form={form} initialValues={currentPret?.pret != null ? currentPret?.pret : {
                    montantPret: "",
                    montantAssurance: "",
                    naturePret: ""
                }}
                onFinish={handleSubmit} 
                >
                    <div className="formItem">
                        <p data-required="true">Montant du prêt à couvrir (en euros)</p>
                        <Form.Item name="montantPret" 
                            rules={[
                                { required: true, message: "Veuillez rentrer votre date de naissance" },
                                { pattern: /^\d+$/, message: "Veuillez entrer uniquement des chiffres" }
                              ]}
                        >
                            <input
                                type="number"
                                className={`input`}
                                name="montantPret"
                            ></input>
                        </Form.Item>
                    </div>
                    <div className="formItem">
                        <p>Montant de l'assurance actuelle (en euros)</p>
                        <Form.Item name="montantAssurance" 
                            rules={[
                                { pattern: /^\d+$/, message: "Veuillez entrer uniquement des chiffres" }
                              ]}
                        >
                            <input
                                type="number"
                                className={`input`}
                                name="montantAssurance"
                            ></input>
                        </Form.Item>
                    </div>
                    <div className="mg-b10 formItem">
                        <p data-required="true">Nature du prêt</p>
                        <Form.Item name="naturePret"> 
                            <ul className="w-100 d-flex radioGroup flex-wrap">
                            {listNaturePret?.map((x: any, index: number) => (
                                <li
                                key={index}
                                className={` mg-b10 radius-4 cursor mg-r10 ${
                                    naturePret === x?.id ? "activeLi" : ""
                                }`}
                                onClick={() => {setNaturePret(x?.id); form.setFieldsValue({naturePret: x?.id})}}
                                >
                                <span className="font-w600 font-14 lh-17">
                                    {x?.libelle}
                                </span>
                                </li>
                            ))}
                            </ul>
                        </Form.Item >
                    </div>
                    {getFormByNaturePret(naturePret)}
                    <button className="submitButton mg-b20" type="submit">
                        Valider mes informations
                    </button>
                </Form>
            </div>
        </div>
    )
}
export default PretForm;