import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import BureauxIcon from "assets/image/mri/bureaux.svg";
import CommercesIcon from "assets/image/mri/commerces.svg";
import HabitationIcon from "assets/image/mri/habitations.svg";
import ParkingIcon from "assets/image/mri/parking.svg";
import { handleNext, selectDossierChoices, setIntermediateStep } from "features/dossierSimuSlice";
import CheckedCard from "features/components/checkedCard/checkedCard";

type Props = {
  handleOnClick: (
    fieldName: string,
    value: boolean | string | any
  ) => void;
  isSide?: boolean
};

const CoOwnerShipFive: React.FC<Props> = ({ handleOnClick, isSide }) => {
  const mri = useAppSelector(selectDossierChoices);
  const dispatch = useAppDispatch();

  const [checkedItems, setCheckedItems] = useState<
    { label: string; value: boolean; icon: any }[]
  >(
    mri && mri.firstElements
      ? mri.firstElements
      : [
        { label: "Bureaux", value: false, icon: BureauxIcon },
        { label: "Commerces", value: false, icon: CommercesIcon },
        { label: "Habitations", value: false, icon: HabitationIcon },
        { label: "Parking", value: false, icon: ParkingIcon },
      ]
  );

  const handleCardClick = (index: number) => {
    const updatedCheckedItems = checkedItems.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          value: !item.value,
        };
      }
      return item;
    });

    setCheckedItems(updatedCheckedItems);
    handleOnClick("firstElements", updatedCheckedItems);
  };

  const isOneOrMoreChecked = checkedItems.some((items) => items.value === true);

  return (
    <div className={`${!isSide ? "max-w1300 mg-0-auto ccc-body-step-2" : ""}`}>
      <div className="w-100 text-center border-box radius-15 cccbs2r1">
        {
          <div className="mg-b50">
              <>
                <div>
                  <p className="font-18 lh-22 text-dark font-w700 cccbs2r1r1">
                    La copropriété comprend-t-elle les éléments suivants ?
                  </p>
                </div>
              </>
          </div>
        }
        <div
          className={`${!isSide ? "d-flex flex-hor-center cccbs2r1r2" : "d-flex flex-hor-start"}`}
          style={{ flexWrap: "wrap" }}
        >
          {checkedItems.map((item, index) => (
            <CheckedCard
              tiny
              key={index + Math.random().toString()}
              label={item.label}
              length={checkedItems.length}
              icon={item.icon}
              checked={item.value}
              onClick={() => handleCardClick(index)}
              isSide={isSide === true ? true : false}
            />
          ))}
        </div>
      </div>
      {
            <div className="d-flex flex-hor-end mg-t10">
              <button
                className="submitButton w-30"
                disabled={!isOneOrMoreChecked}
                onClick={() => dispatch(handleNext())}
              >
                Étape suivante
              </button>
            </div>
        }
    </div>
  );
};

export default CoOwnerShipFive;
