export function parseCBNO(dossierChoices:any, bienId:string, simulationId:string|undefined) {
    const {
      Historique,
      ActivitePrincipaleId,
      Renovation,
      TypeBien,
      TypeContrat,
      Innocupe,
      DateAssurance
    } = dossierChoices;

    console.log(dossierChoices);
    console.log(ActivitePrincipaleId);
  
    const activitePrincipaleId = ActivitePrincipaleId !== undefined && ActivitePrincipaleId != "" ? ActivitePrincipaleId : "";
    const isHistorique = Historique !== undefined && Historique !== null ? Historique : false;
    const isEnRenovation = Renovation !== undefined && Renovation !== null ? Renovation : false; 
    const isBienHabite = Innocupe !== undefined && Innocupe !== null ? Innocupe : false;
  
    const savedData = {
      id: simulationId,
      risqueAssureId: bienId,
      bienImmobilierId: bienId,
      bienImmobilier: {
        bienId: bienId,
        typeBienImmobilierBase: TypeBien || undefined,
        activitePrincipaleId: activitePrincipaleId,
        typeLocationBienImmobilier: TypeContrat || undefined,
        isEnRenovation: isEnRenovation,
        isBienHabite: isBienHabite,
        isMonumentHistorique: isHistorique,
        dateAssurance: DateAssurance
      },
    };
    return savedData;
  }


  export type Devis = {
    devisById: any,
    listDevis: any,
    listDevisSimulation: any,
    listSearchAssureExist: any;
    listGender: any;
    loading: boolean;
    productId: string;
    assure: { name: string, id: string, kind?: string, assureId: string, personneMoraleId: string, personnePhysiqueId: string } | null;
    detailDevis: any,
    bien: any
  }

  export interface Field {
    fieldName: string;
    title: string;
    options: string[];
    selectedOption: string;
  }
  
  export interface Building {
    id: number;
    fields: Field[];
    floors: string;
    basements: string;
  }
  
  export interface BuildingState {
    buildings: Building[];
  }
  

export function parsingMRI(
  dossierChoices: any,
  simulationId: string | undefined,
  coproprieteId: string,
  assureCoproprieteId: string | null,
  activitePrincipaleId: string
) {
  const { surface, address, constructionDate, occupation, firstElements, nextElements, eligibility, residences, commercialParking, executedWorks, propertyCoverageAmount, buildings, numberBuildings, sinistres, superficie, prime } = dossierChoices;
  const { altitude } = address;

  const firstElementsValues = firstElements?.map((element: any) => element.value);
  const nextElementsValues = nextElements?.map((element: any) => element.value);

  const eligibilityOptions = eligibility?.map((item: any) => item.selectedOption);
  const residencesOptions = residences?.map((item: any) => item.selectedOption);

  const executedWorksValues = executedWorks?.map((element: any) => element.value);


  const propertyCoverageAmountValues = {
    commonAreasCoverageAmount: propertyCoverageAmount?.find((item: any) => item.fieldName === 'commonAreasCoverageAmount')?.selectedOption || "",
    privateAreasCoverageAmount: propertyCoverageAmount?.find((item: any) => item.fieldName === 'privateAreasCoverageAmount')?.selectedOption || "",
  };


  const buildingsData = buildings.buildings?.map((building: Building) => ({
    superficie: parseFloat(superficie) || 0,
    nbrEtages: parseInt(building.floors) || 0,
    nbrSousSols: parseInt(building.basements) || 0,
    tauxOccupation: building.fields?.find(item => item.fieldName === 'buildingOccupancyPercent')?.selectedOption === 'Plus de 50%' ? 50 : 0 || 0,
    isRehabilitationDemolition: building.fields?.find((item: any) => item.fieldName === 'buildingDestiny')?.selectedOption === 'Réhabilitation',
    isMoinsDixMetres: building.fields?.find((item: any) => item.fieldName === 'lessThan10mFromOthers')?.selectedOption.startsWith('Oui') || false,
    isSousSolCoupeFeux: building.fields?.find((item: any) => item.fieldName === 'basementsCommunication')?.selectedOption.startsWith('Oui') || false,
  }));

  console.log(activitePrincipaleId);


  const coproprieteData = {
    id: coproprieteId,
    superficie: parseFloat(surface),
    activitePrincipaleId: activitePrincipaleId,
    superficieParking: parseFloat(commercialParking) || 0,
    dateProchaineAG: dossierChoices?.dateAg || undefined,
    isAltitude: altitude > 1500 ? true : false,
    isZoneZus: eligibilityOptions?.includes("Oui"),
    isConstructionMoinsDixAns: constructionDate === "Moins de 10 ans",
    // risqueSisimique: sinistres.garanties.find((item: any) => item.nom === "Cat. nat. et technologique")?.nombre || 0,
    // risqueInondation: sinistres.garanties.find((item: any) => item.nom === "Dégât des eaux")?.nombre || 0,
    // risqueTempete: sinistres.garanties.find((item: any) => item.nom === "Tempête, neige, grêle")?.nombre || 0,
    // risqueArgile: null,
    isTauxOccupationDepasse: occupation === "Plus de 50%",
    isTauxBureauDepasse: firstElementsValues[0],
    isTauxCommerceDepasse: firstElementsValues[1],
    isTauxHabitationDepasse: firstElementsValues[2],
    isTauxParkingCommercialDepasse: firstElementsValues[3],
    hasPiscine: nextElementsValues[0],
    hasGardien: nextElementsValues[1],
    hasTennis: nextElementsValues[2],
    hasLocalPoubelle: nextElementsValues[3],
    hasSurveillance: nextElementsValues[4],
    hasMurObstruant: nextElementsValues[5],
    hasActiviteRI: eligibilityOptions?.includes("Oui"),
    hasActiviteSensible: eligibilityOptions?.includes("Oui"),
    hasActiviteNonSouhaitee: eligibilityOptions?.includes("Oui"),
    hasZoneSecours: eligibilityOptions?.includes("Oui"),
    tauxLotLocation: residencesOptions?.includes("Plus de 50%") ? 1 : 0,
    isAirbnb: residencesOptions?.includes("Oui"),
    isColiving: residencesOptions?.includes("Oui"),
    isTravauxRavalement: executedWorksValues[0],
    isTravauxRefectionToiture: executedWorksValues[1],
    isTravauxChemineeToit: executedWorksValues[2],
    isTravauxCanalisation: executedWorksValues[3],
    montantPartieCommune: parseInt(propertyCoverageAmountValues.commonAreasCoverageAmount?.replace(/[^0-9]/g, ""), 10),
    montantPartiePrivative: parseInt(propertyCoverageAmountValues.privateAreasCoverageAmount?.replace(/[^0-9]/g, ""), 10),
    nbrBatiments: numberBuildings,
    batiments: buildingsData,
    adresses: [
      {
        ligne1: address.address,
        codePostal: address?.codePostal,
        ville: address?.address.split(", ")[1],
        pays: "France",
        adresseFormatee: address.address,
        latitude: address.latitude.toString(),
        longitude: address.longitude.toString(),
      },
    ],
  };

  const savedData = {
    id: simulationId,
    coproprieteId: coproprieteId,
    assureCoproprieteId: assureCoproprieteId,
    copropriete: { ...coproprieteData },
    sinistraliteMRI: {
      debutExercice: sinistres?.debutExercice,
      finExercice: sinistres?.finExercice,
      ddE_Nbr: parseInt(sinistres?.garanties[0]?.nombre) || 0,
      ddE_Fra: parseFloat(sinistres?.garanties[0]?.franchise) || 0,
      ddE_CoutTotal: parseFloat(sinistres?.garanties[0]?.coutTotal) || 0,
      inC_Nbr: parseInt(sinistres?.garanties[1]?.nombre) || 0,
      inC_Fra: parseFloat(sinistres?.garanties[1]?.franchise) || 0,
      inC_CoutTotal: parseFloat(sinistres?.garanties[1]?.coutTotal) || 0,
      eleC_Nbr: parseInt(sinistres?.garanties[2]?.nombre) || 0,
      eleC_Fra: parseFloat(sinistres?.garanties[2]?.franchise) || 0,
      eleC_CoutTotal: parseFloat(sinistres?.garanties[2]?.coutTotal) || 0,
      temP_Nbr: parseInt(sinistres?.garanties[3]?.nombre) || 0,
      temP_Fra: parseFloat(sinistres?.garanties[3]?.franchise) || 0,
      temP_CoutTotal: parseFloat(sinistres?.garanties[3]?.coutTotal) || 0,
      catnaT_Nbr: parseInt(sinistres?.garanties[4]?.nombre) || 0,
      catnaT_Fra: parseFloat(sinistres?.garanties[4]?.franchise) || 0,
      catnaT_CoutTotal: parseFloat(sinistres?.garanties[4]?.coutTotal) || 0,
      rC_Nbr: parseInt(sinistres?.garanties[5]?.nombre) || 0,
      rC_Fra: parseFloat(sinistres?.garanties[5]?.franchise) || 0,
      rC_CoutTotal: parseFloat(sinistres?.garanties[5]?.coutTotal) || 0,
      vehiC_Nbr: parseInt(sinistres?.garanties[6]?.nombre) || 0,
      vehiC_Fra: parseFloat(sinistres?.garanties[6]?.franchise) || 0,
      vehiC_CoutTotal: parseFloat(sinistres?.garanties[6]?.coutTotal) || 0,
      voL_Nbr: parseInt(sinistres?.garanties[7]?.nombre) || 0,
      voL_Fra: parseFloat(sinistres?.garanties[7]?.franchise) || 0,
      voL_CoutTotal: parseFloat(sinistres?.garanties[7]?.coutTotal) || 0,
      briS_GLACE_Nbr: parseInt(sinistres?.garanties[8]?.nombre) || 0,
      briS_GLACE_Fra: parseFloat(sinistres?.garanties[8]?.franchise) || 0,
      briS_GLACE_CoutTotal: parseFloat(sinistres?.garanties[8]?.coutTotal) || 0,
      briS_MACHINE_Nbr: parseInt(sinistres?.garanties[9]?.nombre) || 0,
      briS_MACHINE_Fra: parseFloat(sinistres?.garanties[9]?.franchise) || 0,
      briS_MACHINE_CoutTotal: parseFloat(sinistres?.garanties[9]?.coutTotal) || 0
    },
    contratActuel: {
      primeHorsTaxe: parseFloat(prime?.montant) || 0,
      annee: parseInt(prime?.annee) || 0,
      franchiseGenerale: parseFloat(prime?.franchiseGenerale) || 0,
      franchiseIncendie: parseFloat(prime?.franchiseIncendie) || 0,
      franchiseVol: parseFloat(prime?.franchiseVol) || 0,
      franchiseDDE: parseFloat(prime?.franchiseDDE) || 0,
    }
  };

  return savedData;
}